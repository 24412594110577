import { gql } from "@apollo/client";

export const GET_ITEM_CATEGORIES = gql`
  query item_categories($where: dashboard_reference_bool_exp!) {
    dashboard_reference(order_by: { reference_label: asc }, where: $where) {
      reference_value
      reference_label
    }
  }
`;


export const GET_TICKETING_ITEMS = gql`
  query items($where: items_ticketing_bool_exp) {
    items_ticketing(where: $where, order_by: { name: asc }) {
    is_active
    event_id
    id
    last_updated_by
    order_quantity_limit
    price
    sales_limit
    sales_limit_balance
    token_payload
    tax_percentage
    category
    description
    additional_email_description
    name
    event {
      name
      id
    }
    user {
      id
      username
    }
    created_at
    off_sale_date
    on_sale_date
    updated_at
    unlimited_ingress
    include_egress
    include_pos_tokens
    pos_token_payload
  }
}
`;

export const GET_TICKETING_ITEM = gql`
  query GetTicketingItem($id: Int!) {
    items_ticketing_by_pk(id: $id) {
    is_active
    event_id
    id
    last_updated_by
    order_quantity_limit
    price
    sales_limit
    sales_limit_balance
    tax_percentage
    category
    description
    additional_email_description
    name
    created_at
    off_sale_date
    on_sale_date
    updated_at
    include_egress
    include_pos_tokens
    pos_token_payload
    token_payload
    unlimited_ingress
    available_public_sale
    event {
      id
      name
    }
    user {
      username
    }
    }
  }
`;
