import { ColumnDef } from "@tanstack/table-core";
import { Link } from "react-router-dom";
import Table from "../../../components/Table";
import { Item } from "../../../types/item";
import { capitalize, usdFormat } from "../../../utils";
import { usePermissions } from "../../auth/api";

export const ItemsTable = ({ items }: { items: Item[] }) => {
  const { editCreatePermission: itemsEditPermission } = usePermissions("items");
  const itemsColumns: ColumnDef<Item>[] = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info) => (
        <>
          {itemsEditPermission ? (
            <Link to={`/items/${info.row.original.id}`}>
              {capitalize(info.getValue<string>())}
            </Link>
          ) : (
            <>{capitalize(info.getValue<string>())}</>
          )}
        </>
      ),
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: (info) => usdFormat.format(info.getValue<number>() / 100),
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
    },
    {
      header: "Total",
      accessorKey: "price",
      cell: (info) =>
        usdFormat.format(
          (info.getValue<number>() / 100) * (info.row.original.quantity ?? 1)
        ),
    },
  ];
  // Create an array of unique items by itemId
  const uniqueItems = items.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.id === item.id);
    if (existingItem) {
      existingItem.quantity = (existingItem.quantity ?? 1) + 1;
    } else {
      acc.push({
        ...item,
        quantity: 1,
      });
    }
    return acc;
  }, [] as Item[]);

  return <Table columns={itemsColumns} data={uniqueItems} />;
};

export const ItemsTicketingTable = ({ items }: { items: Item[] }) => {
  const { editCreatePermission: itemsEditPermission } = usePermissions("items");
  const itemsColumns: ColumnDef<Item>[] = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info) => (
          <>
            {itemsEditPermission ? (
                <Link to={`/ticketing-items/${info.row.original.id}/edit`}>
                  {capitalize(info.getValue<string>())}
                </Link>
            ) : (
                <>{capitalize(info.getValue<string>())}</>
            )}
          </>
      ),
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: (info) => usdFormat.format(info.getValue<number>() / 100),
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
    },
    {
      header: "Total",
      accessorKey: "price",
      cell: (info) =>
          usdFormat.format(
              (info.getValue<number>() / 100) * (info.row.original.quantity ?? 1)
          ),
    },
  ];
  // Create an array of unique items by itemId
  const uniqueItems = items.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.id === item.id);
    if (existingItem) {
      existingItem.quantity = (existingItem.quantity ?? 1) + 1;
    } else {
      acc.push({
        ...item,
        quantity: 1,
      });
    }
    return acc;
  }, [] as Item[]);

  return <Table columns={itemsColumns} data={uniqueItems} />;
};