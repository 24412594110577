import {
  Outlet,
  useLocation,
  useNavigate,
  ActionFunctionArgs,
  redirect,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  organizationCreateAction,
  // organizationCreateLoader,
  OrganizationsCreate,
} from "./OrganizationsCreate";
import {
  OrganizationsEdit,
  organizationEditLoader,
  organizationEditAction,
} from "./OrganizationsEdit";
import {
  OrganizationsList,
  organizationsListLoader,
} from "./OrganizationsList";
import { organizationByIdLoader, OrganizationsShow } from "./OrganizationsShow";
import { Organization } from "../../types/organization";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { DELETE_ORGANIZATION } from "./mutations";
import { organizationStore } from "../../store/organization";
import {
  organizationCloneAction,
  organizationCloneLoader,
  OrganizationsClone,
} from "./OrganizationsClone";

const { getState } = organizationStore;

const OrganizationsIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/organizations") {
    navigate(`/organizations/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const organizationDelete = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  const client = createApolloClient();
  try {
    await client.mutate({
      mutation: DELETE_ORGANIZATION,
      variables: { id },
    });
    toast.success("Organization deleted successfully");
    return redirect(`/organizations/list`);
  } catch {
    toast.error("Failed to delete organization");
  }
};

export const organizationsRouter = {
  path: "/organizations",
  id: "organizations",
  element: <OrganizationsIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Organizations" href="/organizations/list" />,
  },
  // loader: requireAuth(ADMIN_ONLY),
  children: [
    {
      index: true,
      path: "list",
      id: "organizations-list",
      element: <OrganizationsList />,
      loader: organizationsListLoader,
    },
    {
      path: "create",
      id: "organizations-create",
      element: <OrganizationsCreate />,
      action: organizationCreateAction,
      // loader: organizationCreateLoader,
      handle: {
        crumb: () => (
          <Breadcrumb name="Create Organization" href="/organizations/create" />
        ),
      },
    },
    {
      path: ":id/clone",
      id: "organizations-clone",
      element: <OrganizationsClone />,
      loader: organizationCloneLoader,
      action: organizationCloneAction,
      handle: {
        crumb: (data: Organization) => (
          <Breadcrumb
            name={`Clone ${data.name}`}
            href={`/organizations/${data.id}/clone`}
          />
        ),
      },
    },
    {
      path: ":id",
      id: "organizations-show",
      element: <OrganizationsShow />,
      loader: organizationByIdLoader,
      handle: {
        crumb: (data: Organization) => (
          <Breadcrumb
            name={data.name}
            href={`/organizations/${data.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "organizations-edit",
      element: <OrganizationsEdit />,
      loader: organizationEditLoader,
      action: organizationEditAction,
      handle: {
        crumb: (data: Organization) => (
          <Breadcrumb
            name={`Edit ${data.name}`}
            href={`/organizations/${data.id}`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "organizations-delete",
      action: organizationDelete,
    },
  ],
};
