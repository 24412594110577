import {
  useLoaderData,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  Form,
  redirect,
} from "react-router-dom";
import toast from "react-hot-toast";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { Input } from "../../../components/inputs/Input";
import { FormLayout } from "../../../layout/FormLayout";
import { useState } from "react";
import {UPDATE_POS_GLOSSARY} from "./mutations";
import {POS_GLOSSARY_BY_ID} from "./queries";
import {Glossary} from "./type";
import BlogEditor from "../../dashboard/components/blogs/BlogEditor";

const client = createApolloClient();


export const ReportingPOSGlossaryEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const { id } = params;
  const data = await request.formData();
  console.log(data.get("content"))
  try {
    await client.mutate({
      mutation: UPDATE_POS_GLOSSARY,
      variables: {
        id,
        reference_value: data.get("content"),
      },
    });
    console.log("Reporting POSGlossary Edit", data);
    toast.success("Reporting Glossary Updated Successfully");
    return redirect("/reporting-glossary");
  } catch (error) {
    toast.error("Failed To Edit Reporting Glossary: " + error);
  }
};

export const ReportingPOSGlossaryEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  try {
    const { data } = await client.query({
      query: POS_GLOSSARY_BY_ID,
      variables: { id },
    });
    return data.dashboard_reference_by_pk;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    throw new Error("Could not fetch organization data");
  }
};
export const ReportingPOSGlossaryEdit = () => {
  const posGlossaryData = useLoaderData() as Glossary;
  const [content, setContent] = useState(posGlossaryData.reference_value);

  console.log(content);
  return (
    <Form action={`/reporting-glossary/${posGlossaryData.id}/edit`} method="put">
      <FormLayout>
        <div className="col-span-6 mt-5">
          <BlogEditor
            content={content}
            setContent={setContent}
            label="Content"
          />
          <Input type="hidden" name="content" value={content} />
        </div>
      </FormLayout>
    </Form>
  );
};
