import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Glossary} from "./type";
import {createApolloClient} from "../../../providers/ApolloClientFactory";
import {SecondaryButton} from "../../../components/Button";
import Loader from "../../../components/Loader";
import {userStore} from "../../../store/user";
import {GET_GLOSSARY_DATA} from "./queries";

const client = createApolloClient();
const {getState: getUserStore} = userStore;


export const reportingPOSGlossaryCreateLoader = async () => {
    const {data} = await client.query({
        query: GET_GLOSSARY_DATA,
        variables: {
            reference_type: "dashboard_pos_glossary",
        },
    });

    return {
        posGlossaryData: data.dashboard_reference,
    };
};

const POSGlossaryShow = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [glossaryData, setGlossaryData] = useState<Glossary>();
    const user = getUserStore().user;
    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const day = date.getUTCDate().toString().padStart(2, "0");
        const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
        const year = date.getUTCFullYear();

        return `${month}/${day}/${year}`;
    };

    console.log({glossaryData})
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const {posGlossaryData} = await reportingPOSGlossaryCreateLoader();
                setGlossaryData(posGlossaryData?.[0]);
            } catch (error) {
                console.error("Failed to fetch Glossary Data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="w-full overflow-hidden rounded-lg bg-white shadow p-5 gap-y-10 py-4">
            {/*<div className="flex-start px-4 pb-5">*/}
            {/*    <ReportHeader title="Reporting Glossary"/>*/}
            {/*</div>*/}
            <div className="flex flex-col w-full items-center">
                {isLoading ? (
                    <div className="bg-gray-200 shadow-xl flex flex-col rounded-lg w-full  min-h-[200px] p-6 mb-5">
                        <Loader/>
                    </div>
                ) : (
                    <ul className="w-full ">
                        {glossaryData ? (
                            <div
                                className="bg-gray-200 shadow-xl rounded-lg w-full  min-h-[200px] p-6 mb-5">
                                <div className=" w-full space-y-2">
                                    <div
                                        className="prose max-w-none custom-quill text-gray-700"
                                        dangerouslySetInnerHTML={{
                                            __html: glossaryData?.reference_value,
                                        }}
                                    ></div>
                                </div>

                                <div className="flex justify-between items-center w-full mt-4">
                            <span className="leading-loose text-gray-500">
                              {formatDate(glossaryData?.updated_at)}
                            </span>
                                    {user?.role?.name === "admin" && (
                                        <Link to={`/reporting-glossary/${glossaryData.id}/edit`}>
                                            <SecondaryButton>Edit</SecondaryButton>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="bg-gray-200 shadow-xl flex rounded-lg w-80 min-h-[250px] my-4 p-6 mx-4">
                                <div className="flex flex-col w-full space-y-2">
                        <span className="text-lg font-bold text-gray-800">
                          Well, This Is Awkward..
                        </span>
                                    <span className="text-base font-medium text-gray-700">
                          No Glossary Entries Available.
                        </span>
                                </div>
                            </div>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default POSGlossaryShow;
