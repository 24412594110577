import { Fragment, ReactNode, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { createApolloClient } from "../../../providers/ApolloClientFactory";

import { useLoaderData } from "react-router-dom";
import { Order } from "../../../types/order";
import { calculateDiscountAmount } from "../util";
import {RefundInput, REFUND_TICKETING_ORDER} from "../mutations";
import { usdFormat } from "../../../utils";
import PriceInput from "../../../components/inputs/PriceInput";
import Loader from "../../../components/Loader";

interface RefundModalProps {
  children: ({ setOpen }: { setOpen: any }) => ReactNode;
  onClose?: () => void;
  partial?: boolean;
}
const client = createApolloClient();

export const RefundTicketingModal = ({
  children,
  onClose,
  partial,
}: RefundModalProps) => {
  const order = useLoaderData() as Order;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const cancelButtonRef = useRef(null);
  const [refundAmount, setRefundAmount] = useState(0);
  const totalDiscount = order.discount
    ? calculateDiscountAmount(order.discount, order.subtotal)
    : 0;

  const total =
    (order.subtotal + order.digital_surcharge + order.tip + order.tax) / 100 -
    totalDiscount;
  console.log("TOTAL: ", total);
  const totalAfterRefund = () => {
    let transactionsTotalRefunded = 0;
    order.transactions?.map((transaction) => {
      const amountRefund =
        transaction?.payment?.status === "refund"
          ? transaction.payment.amount
          : 0;
      return (transactionsTotalRefunded += amountRefund);
    });
    return transactionsTotalRefunded;
  };

  const totalAmountFirstTransaction = () => {
    let transactionAmountFirstTransaction = 0;

    order.transactions?.map((transaction) => {
      let amount =
        transaction?.payment?.status.toLowerCase() === "approved"
          ? transaction.payment.amount
          : 0;
      return (transactionAmountFirstTransaction += amount);
    });
    return transactionAmountFirstTransaction;
  };

  const handleConfirm = async () => {
    setLoading(true);

    const totalOrderWithRefund =
      totalAmountFirstTransaction() - totalAfterRefund();
    const object: RefundInput = {
      order_id: order.id,
      amountRefund: partial ? Math.round(amount * 100) : Math.round(total * 100),
      fullRefund: Boolean(!partial),
      paymentType: order.transactions[0].payment.payment_type,
      totals: totalOrderWithRefund,
      notes: "Refunded from admin",
    };
  console.log({object})
    try {
      await client.mutate({
        mutation: REFUND_TICKETING_ORDER,
        variables: {
          object,
        },
      });
      await client.clearStore();
      toast.success("Refunded successfully");
      setOpen(false);
      onClose && onClose();
    } catch (error) {
      toast.error("Something went wrong: " + error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };
  const handlePriceChange = (value: number) => {
    setRefundAmount(value);
  };

  return (
    <>
      {children({ setOpen })}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                      <QuestionMarkCircleIcon
                        className="h-6 w-6 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Would you like to {partial ? "refund" : "void"} this
                        ticketing order?
                      </Dialog.Title>
                      <div className="mt-2">
                        {partial ? (
                          <PriceInput
                            name="amount"
                            label="Please enter the amount you would like to refund the customer:"
                            type="number"
                            value={refundAmount}
                            onChange={(value) => {
                              setAmount(value as number)
                              handlePriceChange(value ? value : 0);
                            }}
                          />
                        ) : (
                          <p className="text-sm text-gray-500">
                            Voiding this order will refund the full amount of{" "}
                            {usdFormat.format(total)} to the customer.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleConfirm}
                      disabled={loading}
                    >
                      {loading && <Loader sm />}
                      {partial ? ( "Refund" ) : ( "Void")}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={handleClose}
                      ref={cancelButtonRef}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
