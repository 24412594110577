import gql from "graphql-tag";

export const GET_LIST_USERS = gql`
  {
    id
    phone_number
    username
    email
    tablet_access_code
    role_id

    role {
      name
    }

    organization_id
    created_at
    updated_at
  }
`;

export const INSERT_USER = gql`
  {
    id
    phone_number
    password_hash
    username
    email
    tablet_access_code
    role_id
    organization_id
    created_at
  }
`;

export const UPDATE_USER = gql`
  {
    id
    phone_number
    username
    email
    tablet_access_code
    role_id
    organization_id
  }
`;

export const GET_ONE_USER = gql`
  {
    id
    phone_number
    username
    email
    tablet_access_code
    role_id
    organization_id
  }
`;

export const GET_MANY_USER = gql`
  {
    id
    created_at
    updated_at
    phone_number
    username
    email
    tablet_access_code
    role {
      id
      name
    }
  }
`;

export const GET_USER = gql`
  query users($userId: Int!) {
    users_by_pk(id: $userId) {
      id
      phone_number
      username
      email
      tablet_access_code
      vendor_id
      event_id
      vendor {
        id
        name
      }
      role_id
      role {
        name
        display_name
        permissions
      }
      organization_id
      vendors
      events
    }
  }
`;

export const GET_VENDOR_USER = gql`
  query users($userId: Int!) {
    users_by_pk(id: $userId) {
      id
      phone_number
      username
      email
      tablet_access_code
      role_id
      organization_id
      vendor_id
      role {
        display_name
        permissions
      }
      vendor {
        locations {
          id
          event_id
        }
      }
    }
  }
`;
