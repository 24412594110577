import toast from "react-hot-toast";
import { CREATE_DISCOUNT } from "./mutations";
import { GET_DISCOUNT } from "./queries"; // <-- Added
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Input } from "../../components/inputs/Input";
import {
  useLoaderData,
  Form,
  LoaderFunctionArgs,
  ActionFunctionArgs,
  redirect,
} from "react-router-dom";
import { FormLayout } from "../../layout/FormLayout";
import RadioGroup from "../../components/inputs/RadioGroup";
import PriceInput from "../../components/inputs/PriceInput";
import PercentageInput from "../../components/inputs/PercentageInput";
import { useState } from "react";
import { Discount } from "../../types/discount";
import CustomSelect from "../../components/customSelect";
import { useOrganizationStore } from "../../store/organization";
import { userStore } from "../../store/user";
const { getState: getUserStore } = userStore;

const client = createApolloClient();

export const discountCloneLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const { data } = await client.query({
    query: GET_DISCOUNT,
    variables: { id },
  });
  return data.discount_by_pk;
};

export const discountCloneAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const { id } = params;
  const user = getUserStore().user;

  const dataSet = await request.formData();
  const formData = Object.fromEntries(dataSet);

  if (formData.discount_type === "amount") {
    const priceString = dataSet.get("amount") as string;
    const price = parseFloat(priceString.length ? priceString : "0");
    formData.amount = (price * 100).toString();
  }

  try {
    await client.mutate({
      mutation: CREATE_DISCOUNT,
      variables: { data: { ...formData, last_updated_by: user?.id } },
    });
    toast.success("Discount Cloned");
    return redirect("/discounts/list");
  } catch {
    toast.error("Failed To Clone Discount");
  }
};

export const DiscountsClone = () => {
  const data = useLoaderData() as Discount;
  const [type, setType] = useState(data.discount_type);
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const organizations = useOrganizationStore((state) => state.organizations);
  const [price, setPrice] = useState(data.amount / 100);

  const handlePriceChange = (value: number) => {
    setPrice(value);
  };
  const clonedDiscountsName = `${data.name} - clone`;
  return (
    <Form method="post" action={`/discounts/${data.id}/clone`}>
      <FormLayout>
        <div className="col-span-6">
          <Input
            label="Name"
            type="text"
            name="name"
            required
            defaultValue={clonedDiscountsName}
          />
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={data.org.id}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={organizationId ?? ""}
            name="organization_id"
          />
        )}
        <div className="col-span-6">
          <Input
            label="Description"
            type="text"
            name="description"
            required
            defaultValue={data.description}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Code"
            type="text"
            name="code"
            required
            defaultValue={data.code}
          />
        </div>
        <div className="col-span-1">
          <RadioGroup
            label="Type"
            name="discount_type"
            defaultChecked={type}
            onChange={(value) => setType(value)}
            choices={[
              { title: "Percentage", id: "percentage" },
              { title: "Amount", id: "amount" },
            ]}
          />
        </div>
        <div className="col-span-6">
          <div className="w-full mr-4" role="group" aria-labelledby="">
            {type === "percentage" ? (
              <>
                <PercentageInput
                  label="Percentage"
                  name="percentage"
                  defaultValue={data.percentage}
                />
                <input type="hidden" value={0} name="amount" />
              </>
            ) : (
              <>
                <PriceInput
                  label="Amount"
                  name="amount"
                  value={price}
                  onChange={(value) => handlePriceChange(value ? value : 0)}
                />
                <input type="hidden" value={0} name="percentage" />
              </>
            )}
          </div>
        </div>
      </FormLayout>
    </Form>
  );
};
