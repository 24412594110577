import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import { editVendorAction, editVendorLoader, VendorEdit } from "./VendorEdit";
import { VendorList, vendorsListLoader } from "./VendorList";
import { Vendor } from "../../types/vendor";
import { createVendorAction, VendorCreate, vendorLoader } from "./VendorCreate";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import toast from "react-hot-toast";
import { DELETE_VENDOR } from "./mutations";
import {
  cloneVendorAction,
  cloneVendorLoader,
  VendorClone,
} from "./VendorClone";

const client = createApolloClient();
const VendorIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/vendors") {
    navigate(`/vendors/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const vendorDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_VENDOR,
      variables: { id },
    });
    toast.success("Vendor Deleted");
    return redirect(`/vendors/list`);
  } catch (error) {
    toast.error("Failed to delete vendor: " + error);
    return null;
  }
};
export const vendorsRouter = {
  path: "/vendors",
  id: "vendors",
  element: <VendorIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Vendor" href="/vendors/list" />,
  },
  children: [
    {
      path: "list",
      id: "vendors-list",
      element: <VendorList />,
      loader: vendorsListLoader,
    },
    {
      path: "create",
      id: "vendors-create",
      element: <VendorCreate />,
      action: createVendorAction,
      loader: vendorLoader,
      handle: {
        crumb: () => <Breadcrumb name="Create Vendor" href="/vendors/create" />,
      },
    },
    {
      path: ":id/clone",
      id: "vendors-clone",
      element: <VendorClone />,
      action: cloneVendorAction,
      loader: cloneVendorLoader,
      handle: {
        crumb: (data: { vendor: Vendor }) => (
          <Breadcrumb
            name={`Clone ${data.vendor.name}`}
            href={`/vendors/${data.vendor.id}`}
          />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "vendors-edit",
      element: <VendorEdit />,
      loader: editVendorLoader,
      action: editVendorAction,
      handle: {
        crumb: (data: { vendor: Vendor }) => (
          <Breadcrumb
            name={`Edit ${data.vendor.name}`}
            href={`/vendors/${data.vendor.id}`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "vendors-delete",
      action: vendorDeleteAction,
    },
  ],
};
