import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Layout } from "./layout/layout";
import { organizationsRouter } from "./modules/organizations";
import { discountsRouter } from "./modules/discount";
import { eventsRouter } from "./modules/events";
import { authRouter } from "./modules/auth";
import { reportsRouter } from "./modules/reports";
import { dashboardRouter } from "./modules/dashboard";
import { paymentProcessorsRouter } from "./modules/payment_processor_config";
import { usersRouter } from "./modules/users";
import { rfidsRouter } from "./modules/rfid_assets";
import { vendorsRouter } from "./modules/vendors";
import { attendeesRouter } from "./modules/attendees";
import { devicesRouter } from "./modules/devices";
import { createApolloClient } from "./providers/ApolloClientFactory";
import { GET_LIST_EVENTS_BY_ORGANIZATION } from "./queries";
import { organizationStore } from "./store/organization";
import { itemsRouter } from "./modules/items";
import { locationsRouter } from "./modules/locations";
import { menusRouter } from "./modules/menus";
import { ordersRouter } from "./modules/orders";
import { transactionsRouter } from "./modules/transactions";
import { ErrorLayout } from "./layout/ErrorLayout";
import { userStore } from "./store/user";
import { notFoundRouter } from "./modules/not-found";
import "./App.css";
import { itemModifiersRouter } from "./modules/item-modifiers";
import { rolesRouter } from "./modules/roles";
import { promoCodeRouter } from "./modules/promo-code";
import { qrCodeRouter } from "./modules/qr-code";
import { invoicesRouter } from "./modules/invoices";
import { ticketsRouter } from "./modules/tickets";
import { blogpostRouter } from "./modules/dashboard/blogPost";
import { blogpostPosRouter } from "./modules/dashboard/blogPostPos";
import { profileRouter } from "./modules/profile";
import {ticketingItemsRouter} from "./modules/tickets/ticket-items";
import {posGlossaryRouter} from "./modules/reports/reporting-glossary";

const client = createApolloClient();

const rootLoader = async () => {
  const { getState } = organizationStore;
  const { getState: getUserState } = userStore;

  const organizationId = getState().organizationId;

  const user = getUserState().user;

  if (user && organizationId) {
    const { data } = await client.query({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where: organizationId
          ? {
              organization_id: { _eq: organizationId },
            }
          : {},
      },
    });
    return {
      events: data.events,
    };
  }
  return {};
};

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <Layout />,
    children: [
      // Resource routes
      attendeesRouter,
      authRouter,
      dashboardRouter,
      devicesRouter,
      discountsRouter,
      promoCodeRouter,
      eventsRouter,
      itemsRouter,
      itemModifiersRouter,
      locationsRouter,
      menusRouter,
      ordersRouter,
      organizationsRouter,
      paymentProcessorsRouter,
      profileRouter,
      reportsRouter,
      rfidsRouter,
      ticketsRouter,
      transactionsRouter,
      usersRouter,
      vendorsRouter,
      rolesRouter,
      qrCodeRouter,
      invoicesRouter,
      blogpostRouter,
      blogpostPosRouter,
      posGlossaryRouter,
      ticketingItemsRouter,
      // Error routes
      notFoundRouter,
    ],
    errorElement: <ErrorLayout />,
    loader: rootLoader,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
