import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  ItemModifierEdit,
  itemEditAction,
  itemEditLoader,
} from "./ItemModifierEdit";
import { ItemModifierList, itemsListLoader } from "./ItemModifierList";
import { Item } from "../../types/item";
import {
  ItemModifierCreate,
  itemCreateAction,
  itemCreateLoader,
} from "./ItemModifierCreate";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { DELETE_ITEM_MODIFIER } from "./mutations";
import {
  ItemModifierClone,
  itemModifierCloneAction,
  itemModifierCloneLoader,
} from "./ItemModifierClone";

const client = createApolloClient();

const ItemModifiersIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/items") {
    navigate(`/items/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const itemDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_ITEM_MODIFIER,
      variables: { id },
    });
    toast.success("Item Modifier Deleted");
    return redirect(`/item-modifiers/list`);
  } catch {
    toast.error(
      "Failed to delete item modifier, Check Items and Remove Modifiers"
    );
    return null;
  }
};

export const itemModifiersRouter = {
  path: "/item-modifiers",
  id: "item-modifiers",
  element: <ItemModifiersIndex />,
  handle: {
    crumb: () => (
      <Breadcrumb name="Item Modifiers" href="/item-modifiers/list" />
    ),
  },
  children: [
    {
      path: "list",
      id: "item-modifiers-list",
      element: <ItemModifierList />,
      loader: itemsListLoader,
    },
    {
      path: "create",
      id: "item-modifiers-create",
      element: <ItemModifierCreate />,
      action: itemCreateAction,
      loader: itemCreateLoader,
      handle: {
        crumb: () => (
          <Breadcrumb
            name={`Create Item Modifier`}
            href={`/item-modifiers/create`}
          />
        ),
      },
    },
    {
      path: ":id/clone",
      id: "item-modifiers-clone",
      element: <ItemModifierClone />,
      action: itemModifierCloneAction,
      loader: itemModifierCloneLoader,
      handle: {
        crumb: (data: Item) => (
          <Breadcrumb
            name={`Clone ${data.name}`}
            href={`/item-modifiers/${data.id}`}
          />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "item-modifiers-edit",
      element: <ItemModifierEdit />,
      loader: itemEditLoader,
      action: itemEditAction,
      handle: {
        crumb: (data: Item) => (
          <Breadcrumb
            name={`Edit ${data.name}`}
            href={`/item-modifiers/${data.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "item-modifiers-delete",
      action: itemDeleteAction,
    },
  ],
};
