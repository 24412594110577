import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Input, TextArea } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { DatePicker } from "../../components/DatePicker";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_EVENT } from "./queries";
import { CREATE_EVENT } from "./mutation";
import { Event } from "../../types/event";
import {
  GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import { PaymentProcessor } from "../../types/paymentProcessor";
import { Vendor } from "../../types/vendor";
import { parseDate } from "@internationalized/date";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { TagsInput } from "../../components/inputs/TagsInput";
import { useState } from "react";
import PercentageInput from "../../components/inputs/PercentageInput";
import { userStore } from "../../store/user";
type PaymentType = "card" | "cash" | "rfid" | "qr";
const client = createApolloClient();

const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

export const eventCloneLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getState().organizationId;
  const { id } = params;
  const [
    { data: eventData },
    { data: paymentProcessors },
    { data: vendorData },
  ] = await Promise.all([
    client.query<{ events_by_pk: Event }>({
      query: GET_EVENT,
      variables: { id },
    }),
    client.query({
      query: GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
      variables: {
        where: organizationId
          ? {
              organization_id: {
                _eq: organizationId,
              },
            }
          : {},
      },
    }),
    client.query({
      query: GET_LIST_VENDORS_BY_ORGANIZATION,
      variables: {
        where: organizationId
          ? {
              organization_id: {
                _eq: organizationId,
              },
            }
          : {},
      },
    }),
  ]);
  return {
    event: eventData.events_by_pk,
    paymentProcessors: paymentProcessors.payment_processor_config,
    vendors: vendorData.vendors,
  };
};

export const eventCloneAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const { id } = params;
  const user = getUserStore().user;

  const body = await request.formData();
  let timezonediffs = 0;
  const paymentTypes: PaymentType[] = body.getAll(
    "payment_types"
  ) as PaymentType[];
  body.delete("payment_types");
  const tokensString = body.get("available_tokens") as string;
  const available_tokens =
    tokensString.length > 0
      ? tokensString.split(",").map((tag) => tag.trim())
      : [];
  const zonesString = body.get("location_zones") as string;
  const location_zones =
    zonesString.length > 0
      ? zonesString.split(",").map((tag) => tag.trim())
      : [];
  const tz = body.get("timezone");
  if (tz === "EDT") {
    timezonediffs = -4;
  } else if (tz === "EST") {
    timezonediffs = -5;
  } else if (tz === "CDT") {
    timezonediffs = -5;
  } else if (tz === "CST") {
    timezonediffs = -6;
  } else if (tz === "MDT") {
    timezonediffs = -6;
  } else if (tz === "MST") {
    timezonediffs = -7;
  } else if (tz === "PDT") {
    timezonediffs = -7;
  } else if (tz === "PST") {
    timezonediffs = -8;
  } else {
    timezonediffs = 0;
  }
  const slug = body.get("slug");

  if (slug === "") {
    body.delete("slug");
  } else if (typeof slug === "string") {
    const lowerCaseSlug = slug.trim().toLowerCase();
    body.set("slug", lowerCaseSlug);
  }
  const bodyData = Object.fromEntries(body);

  try {
    await client.mutate({
      mutation: CREATE_EVENT,
      variables: {
        id,
        input: {
          ...bodyData,
          available_tokens,
          location_zones,
          payment_types: paymentTypes,
          start_date: bodyData?.start_date || null,
          end_date: bodyData?.end_date || null,
          frontgate_site_id: bodyData?.frontgate_site_id || null,
          payment_processor_id: bodyData?.payment_processor_id || null,
          timezone_diff: timezonediffs,
          web_surcharge_percentage: bodyData?.web_surcharge_percentage || 0,
          last_updated_by: user?.id,
        },
      },
    });
    toast.success("Event Cloned");
    return redirect("/events/list");
  } catch (error) {
    toast.error("Failed To Clone Event: " + error);
  }
};

export const EventClone = () => {
  const organizations = useOrganizationStore((state) => state.organizations);
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const { event, paymentProcessors } = useLoaderData() as {
    event: Event;
    paymentProcessors: PaymentProcessor[];
    vendors: Vendor[];
  };
  const defaultStartDate = event.start_date
    ? parseDate(event.start_date)
    : null;
  const defaultEndDate = event.end_date ? parseDate(event.end_date) : null;
  const [slugInputValue, setSlugInputValue] = useState(event.slug);
  const [ticketingSlugInputValue, setTicketingSlugInputValue] = useState(
    event.ticketing_slug
  );
  const sortedPaymentProcessors = [...paymentProcessors].sort((a, b) =>
    a.payment_processor.localeCompare(b.payment_processor)
  );
  const [logoutProtected, setLogoutProtected] = useState(
    event?.is_org_logout_protected
  );
  const clonedEventName = `${event.name} - clone`;
  return (
    <Form
      method="post"
      action={`/events/${event.id}/clone`}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <FormLayout>
        <div className="col-span-4">
          <Input
            label="Event Name"
            name="name"
            defaultValue={clonedEventName}
          />
        </div>
        <div className="col-span-2">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={event.is_active}
          />
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={event?.organization_id}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={event?.organization_id ?? ""}
            name="organization_id"
          />
        )}
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Event Information
          </label>
          <div className="col-span-3">
            <DatePicker
              label="Start Date"
              name="start_date"
              defaultValue={defaultStartDate}
            />
          </div>
          <div className="col-span-3">
            <DatePicker
              label="End Date"
              name="end_date"
              defaultValue={defaultEndDate}
            />
          </div>
          <div className="col-span-3">
            <Input
              label="Start Time"
              name="start_time"
              defaultValue={event.start_time}
            />
          </div>
          <div className="col-span-3">
            <Input
              label="End Time"
              name="end_time"
              defaultValue={event.end_time}
            />
          </div>
          <div className="col-span-6">
            <CustomSelect
              title="Timezone"
              name="timezone"
              required
              defaultValue={event.timezone}
              options={[
                { value: "EDT", label: "Eastern Daylight Time" },
                { value: "EST", label: "Eastern Standard Time" },
                { value: "CDT", label: "Central Daylight Time" },
                { value: "CST", label: "Central Standard Time" },
                { value: "MDT", label: "Mountain Daylight Time" },
                { value: "MST", label: "Mountain Standard Time" },
                { value: "PDT", label: "Pacific Daylight Time" },
                { value: "PST", label: "Pacific Standard Time" },
              ]}
            />
          </div>
          <div className="col-span-3">
            <Input
              label="Street Address"
              name="address_street"
              defaultValue={event.address_street}
            />
          </div>
          <div className="col-span-3">
            <Input
              label="Street Address 2"
              name="address_street_2"
              defaultValue={event.address_street_2}
            />
          </div>
          <div className="col-span-3">
            <Input
              label="City"
              name="address_city"
              defaultValue={event.address_city}
            />
          </div>
          <div className="col-span-1">
            <Input
              label="State"
              name="address_state"
              defaultValue={event.address_state}
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Zipcode"
              name="address_zipcode"
              defaultValue={event.address_zipcode}
            />
          </div>
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Payment Configuration
          </label>
          <div className="col-span-6">
            <CustomSelect
              name="payment_processor_id"
              title="Payment Processor"
              defaultValue={event.payment_processor_id}
              options={[
                { label: "PENDING", value: "" },
                ...sortedPaymentProcessors.map((p) => ({
                  value: p.id,
                  label: p.payment_processor,
                })),
              ]}
            />
          </div>
          <>
            <div className="col-span-6">
              <Input
                label="Processor Dynamic Descriptor"
                placeholder="4 Character DBA Abbreviation * Event Name Example: RPOS*RONIN LOCATION (Max 25 Characters)"
                name="dynamic_descriptor"
                defaultValue={event.dynamic_descriptor}
              />
            </div>
          </>
          <div className="col-span-6">
            <CustomSelect
              title="Payments"
              name="payment_types"
              defaultValue={event.payment_types}
              multi
              options={[
                { value: "card", label: "Card" },
                { value: "cash", label: "Cash" },
                { value: "rfid", label: "RFID" },
                { value: "qr", label: "QR" },
              ]}
            />
          </div>
          <div className="col-span-6">
            <CustomSelect
              title="Currency"
              name="currency"
              defaultValue={event.currency}
              options={[{ value: "USD", label: "USD" }]}
            />
          </div>
          <div className="col-span-6 sm:col-span-6">
            <TagsInput
              label="Eligible Token Types"
              name="available_tokens"
              placeholder="Add a Token Type (press 'enter' to add)"
              defaultValue={event.available_tokens}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Service Fee Label"
              name="digital_surcharge_label"
              defaultValue={event.digital_surcharge_label}
            />
          </div>
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Feature Configuration
          </label>
          <div className="col-span-6 sm:col-span-6">
            <TagsInput
              label="Event Zones"
              name="location_zones"
              placeholder="Add a Zone Name (press 'enter' to add)"
              defaultValue={event.location_zones}
            />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
              label="Password Protected Organizer Logout"
              name="is_org_logout_protected"
              defaultChecked={event?.is_org_logout_protected}
              onChange={(checked) => setLogoutProtected(checked as boolean)}
            />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
              label="Password Protected Clerk Logout"
              name="is_clerk_logout_protected"
              defaultChecked={event?.is_clerk_logout_protected}
              onChange={(checked) => setLogoutProtected(checked as boolean)}
            />
          </div>
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Cashless Configuration
          </label>
          <div className="col-span-6">
            <Input
              label="Frontgate Site Id"
              name="frontgate_site_id"
              defaultValue={event?.frontgate_site_id}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Cashless URL Slug"
              name="slug"
              value={slugInputValue}
              disallowUnderscore={true}
              onChange={(value) => setSlugInputValue(value as string)}
            />
          </div>
          <div className="block text-sm font-medium text-gray-700 col-span-6">
            <a
              href={`https://cashless.roninpos.app/events/${slugInputValue}`}
              target="_blank"
              rel="noopener noreferrer"
              className="link-underline"
            >
              https://cashless.roninpos.app/events/{slugInputValue}
            </a>
          </div>
          <div className="col-span-6">
            <Input
              label="Ticketing URL Slug"
              name="ticketing_slug"
              value={ticketingSlugInputValue}
              onChange={(value) => setTicketingSlugInputValue(value as string)}
            />
          </div>
          <div className="block text-sm font-medium text-gray-700 col-span-6">
            <a
              href={`https://ticketing.roninpos.app/events/${ticketingSlugInputValue}`}
              target="_blank"
              rel="noopener noreferrer"
              className="link-underline"
            >
              https://ticketing.roninpos.app/events/{ticketingSlugInputValue}
            </a>
          </div>
          <div className="col-span-6">
            <Input
              label="Cashless/Ticketing Logo URL"
              name="logo"
              defaultValue={event.logo}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Cashless/Ticketing Background URL"
              name="background_url"
              defaultValue={event.background_url}
            />
          </div>
          <div className="col-span-6">
            <PercentageInput
              name="web_surcharge_percentage"
              label="Ticketing Site Service Fee Value (%)"
              defaultValue={event.web_surcharge_percentage}
            />
          </div>
        </div>
        <div className="col-span-6">
          <TextArea
            label="Event Description"
            name="description"
            defaultValue={event.description}
          />
        </div>
      </FormLayout>
    </Form>
  );
};
