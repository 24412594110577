/* eslint-disable jsx-a11y/anchor-is-valid */
import POSGlossaryShow from "./posGlossaryShow";
import {
    ReportingPOSGlossaryEdit,
    ReportingPOSGlossaryEditAction,
    ReportingPOSGlossaryEditLoader
} from "./posGlossaryEdit";
import {Outlet} from "react-router-dom";



export const ReportingPOSGlossary = () => {
    return (
        <div>
            <Outlet />
        </div>
    );
};


export const posGlossaryRouter = {
    path: "/reporting-glossary",
    id: "reporting-glossary",
    element: <ReportingPOSGlossary />,
    children: [
        {
            index: true,
            element: <POSGlossaryShow />,
        },
        {
            path: ":id/edit",
            id: "reporting-glossary-edit",
            element: <ReportingPOSGlossaryEdit />,
            loader: ReportingPOSGlossaryEditLoader,
            action: ReportingPOSGlossaryEditAction,
        },
    ],
};
