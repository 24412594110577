import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../components/Breadcrumb";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Menu } from "../../types/menu";
import { menuCreateAction, MenuCreate, menuCreateLoader } from "./MenuCreate";
import { menuEditAction, menuEditLoader, MenuEdit } from "./MenuEdit";
import { MenuList, menusListLoader } from "./MenuList";
import { menuShowLoader, MenuShow } from "./MenuShow";
import {
  DELETE_LOCATION_MENUS,
  DELETE_MENU,
  DELETE_MENU_ITEMS,
} from "./mutations";
import { MenuClone, menuCloneAction, menuCloneLoader } from "./MenuClone";

const MenusIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/menus") {
    navigate(`/menus/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const client = createApolloClient();

const menuDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await Promise.all([
      client.mutate({
        mutation: DELETE_MENU,
        variables: { id },
      }),
      client.mutate({
        mutation: DELETE_LOCATION_MENUS,
        variables: { id },
      }),
      client.mutate({
        mutation: DELETE_MENU_ITEMS,
        variables: { id },
      }),
    ]);
    toast.success("Menu deleted");
    return redirect("/menus/list");
  } catch {
    toast.error("Menu could not be deleted");
  }
};

export const menusRouter = {
  path: "/menus",
  id: "menus",
  element: <MenusIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Menus" href="/menus/list" />,
  },
  children: [
    {
      path: "list",
      id: "menus-list",
      element: <MenuList />,
      loader: menusListLoader,
    },
    {
      path: "create",
      id: "menus-create",
      element: <MenuCreate />,
      loader: menuCreateLoader,
      action: menuCreateAction,
      handle: {
        crumb: () => <Breadcrumb name="Create Menu" href="/menus/create" />,
      },
    },
    {
      path: ":id/clone",
      id: "menus-clone",
      element: <MenuClone />,
      action: menuCloneAction,
      loader: menuCloneLoader,
      handle: {
        crumb: (data: { menu: Menu }) => (
          <Breadcrumb
            name={`Clone ${data.menu.name}`}
            href={`/menus/${data.menu.id}`}
          />
        ),
      },
    },
    {
      path: ":id",
      id: "menus-show",
      element: <MenuShow />,
      loader: menuShowLoader,
      handle: {
        crumb: (data: { menu: Menu }) => (
          <Breadcrumb name={data.menu.name} href={`/menus/${data.menu.id}`} />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "menus-edit",
      element: <MenuEdit />,
      loader: menuEditLoader,
      action: menuEditAction,
      handle: {
        crumb: (data: { menu: Menu }) => (
          <Breadcrumb
            name={`Edit ${data.menu.name}`}
            href={`/menus/${data.menu.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "menus-delete",
      action: menuDeleteAction,
    },
  ],
};
