import { useState } from "react";
import toast from "react-hot-toast";
import {
  Form,
  redirect,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
} from "react-router-dom";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { PaymentProcessor } from "../../types/paymentProcessor";
import { CreatePaymentProcessor } from "./mutations";
import { PAYMENT_PROCESSOR_CATAGORIES, PaymentProcessorById } from "./queries";
import PercentageInput from "../../components/inputs/PercentageInput";
import { userStore } from "../../store/user";
import { Categories } from "../../types/item";

const client = createApolloClient();
const { getState: getUserStore } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const paymentProcessorCloneLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const { id } = params;
  const organizationId = getOrganizationStore().organizationId;
  const { data } = await client.query({
    query: PaymentProcessorById,
    variables: {
      id,
    },
  });
  const { data: paymentTypeData } = await client.query({
    query: PAYMENT_PROCESSOR_CATAGORIES,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "payment_processors_processor",
            },
          }
        : {},
    },
  });
  return {
    paymentProcessor: data.payment_processor_config_by_pk,
    paymentType: paymentTypeData.dashboard_reference,
  };
};

export const paymentProcessorCloneAction = async ({
  request,
}: ActionFunctionArgs) => {
  const user = getUserStore().user;

  const formData = await request.formData();
  const body = Object.fromEntries(formData.entries());
  try {
    const processingRate = (body.processing_rate || "0") as string;
    const processingRatePer = (parseFloat(processingRate) / 100).toFixed(2);

    await client.mutate({
      mutation: CreatePaymentProcessor,
      variables: {
        input: {
          payment_processor: body.payment_processor,
          processor: body.processor,
          organization_id: body.organization_id,
          config: {
            secretKey: body.secretKey,
            merchantId: body.merchantId,
            consumerKey: body.consumerKey,
            merchantAccountId: body.merchantAccountId || null,
          },
          is_active: body.is_active === "true",
          processing_rate: processingRatePer,
          last_updated_by: user?.id,
        },
      },
    });

    toast.success("Payment Processor Cloned");

    return redirect("/payment-processors/list", 302);
  } catch {
    toast.error("Unable To Clone Payment Processor");
  }
};

export const PaymentProcessorClone = () => {
  const { paymentProcessor, paymentType } = useLoaderData() as {
    paymentProcessor: PaymentProcessor;
    paymentType: Categories[];
  };
  const [organization, setOrganization] = useState(
    paymentProcessor.organization_id
  );
  const [processor, setProcessor] = useState(paymentProcessor.processor);
  const organizations = useOrganizationStore((state) => state.organizations);
  const clonedPaymentProcessorName = `${paymentProcessor.payment_processor} - clone`;
  return (
    <Form
      method="post"
      action={`/payment-processors/${paymentProcessor.id}/clone`}
    >
      <FormLayout>
        <div className="col-span-4">
          <CustomSelect
            title="Organization"
            name="organization_id"
            defaultValue={organization}
            required
            onChange={setOrganization}
            options={organizations.map((org) => ({
              value: org.id,
              label: org.name,
            }))}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={paymentProcessor.is_active}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Processor"
            required
            name="processor"
            defaultValue={paymentProcessor.processor}
            onChange={(value) => setProcessor(value as string)}
            options={[
              ...(paymentType?.map((v) => ({
                label: v.reference_label,
                value: v.reference_value,
              })) || []),
            ]}
          />
        </div>
        {processor === "mxmerchant" && (
          <>
            <div className="col-span-6">
              <Input
                label="Payment Processor Name"
                name="payment_processor"
                required
                defaultValue={clonedPaymentProcessorName}
              />
            </div>
            <div className="col-span-6">
              <Input
                label="Consumer Key"
                name="consumerKey"
                required
                defaultValue={paymentProcessor.config.consumerKey}
              />
            </div>
            <div className="col-span-6">
              <Input
                label="Secret Key"
                name="secretKey"
                required
                defaultValue={paymentProcessor.config.secretKey}
              />
            </div>
            <div className="col-span-6">
              <Input
                label="Merchant ID"
                name="merchantId"
                required
                defaultValue={paymentProcessor.config.merchantId}
              />
            </div>
          </>
        )}
        {processor === "braintree" && (
          <>
            <div className="col-span-6">
              <Input
                label="Payment Processor Name"
                name="payment_processor"
                required
                defaultValue={clonedPaymentProcessorName}
              />
            </div>
            <div className="col-span-6">
              <Input
                label="Public Key"
                name="consumerKey"
                required
                defaultValue={paymentProcessor.config.consumerKey}
              />
            </div>
            <div className="col-span-6">
              <Input
                label="Private Key"
                name="secretKey"
                required
                defaultValue={paymentProcessor.config.secretKey}
              />
            </div>
            <div className="col-span-6">
              <Input
                label="Merchant ID"
                name="merchantId"
                required
                defaultValue={paymentProcessor.config.merchantId}
              />
            </div>
            <div className="col-span-6">
              <Input
                label="Merchant Account ID"
                name="merchantAccountId"
                required
                defaultValue={paymentProcessor.config.merchantAccountId}
              />
            </div>
          </>
        )}
        {processor ? (
          <div className="col-span-6">
            <PercentageInput
              defaultValue={(
                (paymentProcessor?.processing_rate || 0) * 100
              ).toFixed(2)}
              label="Processing Rate %"
              name="processing_rate"
            />
          </div>
        ) : (
          ""
        )}
      </FormLayout>
    </Form>
  );
};
