import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { TicketingItemEdit, ticketingItemEditLoader, ticketingItemEditAction } from "./TicketingItemEdit";
import { TicketingItemList, ticketingItemsListLoader } from "./TicketingItemList";
import { Item } from "../../../types/item";
import { TicketingItemCreate,ticketingItemCreateLoader, ticketingItemCreateAction } from "./TicketingItemCreate";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import {DELETE_TICKETING_ITEM} from "./mutations";
import {ItemClone, itemCloneAction, itemCloneLoader} from "../../items/ItemClone";
import {TicketingItemClone, ticketingItemCloneAction, ticketingItemCloneLoader} from "./TicketingItemClone";

const client = createApolloClient();

const TicketingItemIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/ticketing-items") {
    navigate(`/ticketing-items/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const ticketingItemDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_TICKETING_ITEM,
      variables: { id },
    });
    toast.success("Ticketing Item Deleted");
    return redirect(`/ticketing-items/list`);
  } catch {
    toast.error("Failed to delete Ticketing Item");
    return null;
  }
};

export const ticketingItemsRouter = {
  path: "/ticketing-items",
  id: "ticketing-items",
  element: <TicketingItemIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Ticketing Items" href="/ticketing-items/list" />,
  },
  children: [
    {
      path: "list",
      id: "ticketing-items-list",
      element: <TicketingItemList />,
      loader: ticketingItemsListLoader,
    },
    {
      path: "create",
      id: "ticketing-items-create",
      element: <TicketingItemCreate />,
      action: ticketingItemCreateAction,
      loader: ticketingItemCreateLoader,
      handle: {
        crumb: () => <Breadcrumb name={`Create Ticketing Item`} href={`/ticketing-items/create`} />,
      },
    },
    {
      path: ":id/edit",
      id: "ticketing-items-edit",
      element: <TicketingItemEdit />,
      loader: ticketingItemEditLoader,
      action: ticketingItemEditAction,
      handle: {
        crumb: (data: Item) => (
          <Breadcrumb
            name={`Edit ${data.name}`}
            href={`/ticketing-items/${data.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/clone",
      id: "ticketing-items-clone",
      element: <TicketingItemClone />,
      action: ticketingItemCloneAction,
      loader: ticketingItemCloneLoader,
    },
    {
      path: ":id/delete",
      id: "ticketing-items-delete",
      action: ticketingItemDeleteAction,
    },
  ],
};
