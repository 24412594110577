import { ElementType, ReactNode, useRef, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useButton, AriaButtonProps } from "@react-aria/button";
import { useFocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import Loader from "./Loader";
import { classNames } from "../utils";

export function CalendarButton(props: ButtonProps) {
  let ref = useRef<HTMLButtonElement>(null);
  let { buttonProps } = useButton(props, ref);
  let { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={`p-2 rounded-full ${props.isDisabled ? "text-gray-400" : ""} ${
        !props.isDisabled ? "hover:bg-violet-100 active:bg-violet-200" : ""
      } outline-none ${
        isFocusVisible ? "ring-2 ring-offset-2 ring-primary-600" : ""
      }`}
    >
      {props.children}
    </button>
  );
}

export function FieldButton(props: ButtonProps) {
  let ref = useRef<HTMLButtonElement>(null);
  let { buttonProps, isPressed } = useButton(props, ref);
  return (
    <button
      {...buttonProps}
      ref={ref}
      className={`px-2 -ml-px border transition-colors rounded-r-md group-focus-within:border-primary-600 group-focus-within:group-hover:border-primary-600 outline-none ${
        isPressed || props.isPressed
          ? "bg-gray-200 border-gray-400"
          : "bg-gray-50 border-gray-300 group-hover:border-gray-400"
      }`}
    >
      {props.children}
    </button>
  );
}

interface ButtonProps extends AriaButtonProps<ElementType<any>> {
  children: ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  isPressed?: boolean;
  isDisabled?: boolean;
}

export function PrimaryButton(props: ButtonProps) {
  return (
    <button
      disabled={props.isDisabled || props.isLoading}
      type="button"
      className="inline-flex justify-center ml-2 mr-2 items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white line-height-same-size shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
      onClick={props.onClick}
      style={{ lineHeight: 2 }}
      {...props}
    >
      {props.isLoading ? <Loader /> : props.children}
    </button>
  );
}

export function TertiaryButton(props: ButtonProps) {
  return (
      <button
          disabled={props.isDisabled || props.isLoading}
          type="button"
          className="inline-flex justify-center ml-2 mr-2 items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white line-height-same-size shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          onClick={props.onClick}
          style={{ lineHeight: 2 }}
          {...props}
      >
        {props.isLoading ? <Loader /> : props.children}
      </button>
  );
}

export function ToggleButton(props: ButtonProps & { isSelected: boolean }) {
  const { isSelected, ...restProps } = props;

  return (
      <button
          disabled={props.isDisabled || props.isLoading}
          type="button"
          className={`inline-flex justify-center items-center rounded-md border border-gray-300 px-4 py-2 pr-3 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 ${
              isSelected
                  ? 'bg-primary-600 text-white hover:bg-primary-700'
                  : 'bg-white text-gray-700 hover:bg-gray-100'
          }`}
          style={{
            width: '140px', // Set your desired width here
            whiteSpace: 'normal', // Allow text to wrap within the button
            lineHeight: '1', // Adjust line height for better text readability
            marginRight: '5px',
            marginBottom: '5px',
          }}
          {...restProps}
      >
        {props.isLoading ? <Loader /> : props.children}
      </button>
  );
}

export function LoginButton(props: ButtonProps) {
  return (
      <button
          disabled={props.isDisabled || props.isLoading}
          type="button"
          className="inline-flex justify-center mr-2 items-center rounded-md border border-transparent bg-primary-600 w-full py-2 text-sm font-medium text-white line-height-same-size shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          onClick={props.onClick}
          style={{ lineHeight: 1 }}
          {...props}
      >
        {props.isLoading ? <Loader /> : props.children}
      </button>
  );
}

export function DeleteButton(props: ButtonProps) {
  return (
    <button
      disabled={props.isDisabled || props.isLoading}
      type="button"
      className="inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 line-height-same-size shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
      onClick={props.onClick}
      style={{ lineHeight: 2 }}
      {...props}
    >
      {props.isLoading ? <Loader /> : props.children}
    </button>
  );
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <button
      disabled={props.isDisabled || props.isLoading}
      type="button"
      className="inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
      style={{ lineHeight: 2 }}
      onClick={props.onClick}
      {...props}
    >
      {props.isLoading ? <Loader /> : props.children}
    </button>
  );
}

interface DropdownButton {
  name: string;
  onClick: () => void;
}

export default function ButtonGroup({
  mainButton,
  buttons,
  loading,
}: {
  loading?: boolean;
  mainButton: DropdownButton;
  buttons: DropdownButton[];
}) {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
        onClick={mainButton.onClick}
        disabled={loading}
      >
        {loading && <Loader sm />}
        {mainButton.name}
      </button>
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {buttons.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      onClick={item.onClick}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
