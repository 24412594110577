import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../components/Breadcrumb";
import { UserCreate, userCreateAction, userCreateLoader } from "./UserCreate";
import { editUserAction, userByIdLoader, UserEdit } from "./UserEdit";
import { UsersList, usersListLoader } from "./UserList";
import { OrganizationUser } from "../../types/organizationUser";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { DELETE_ORGANIZATION_USER } from "./mutations";
import { UserClone, userCloneAction, userCloneLoader } from "./UserClone";

const client = createApolloClient();

const UserIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/users") {
    navigate(`/users/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const userDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_ORGANIZATION_USER,
      variables: { id },
    });
    toast.success("User deleted");
    return redirect("/users/list");
  } catch {
    toast.error("Error deleting user");
  }
};

export const usersRouter = {
  path: "/users",
  id: "users",
  element: <UserIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Users" href="/users/list" />,
  },
  children: [
    {
      path: "list",
      id: "users-list",
      element: <UsersList />,
      loader: usersListLoader,
    },
    {
      path: "create",
      id: "users-create",
      element: <UserCreate />,
      handle: {
        crumb: () => <Breadcrumb name="Create User" href="/users/create" />,
      },
      action: userCreateAction,
      loader: userCreateLoader,
    },
    {
      path: ":id/clone",
      id: "users-clone",
      element: <UserClone />,
      action: userCloneAction,
      loader: userCloneLoader,
      handle: {
        crumb: (data: OrganizationUser) => (
          <Breadcrumb
            name={`Clone ${data.username}`}
            href={`/users/${data.id}`}
          />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "users-edit",
      element: <UserEdit />,
      loader: userByIdLoader,
      action: editUserAction,
      handle: {
        crumb: (data: OrganizationUser) => (
          <Breadcrumb
            name={`Edit ${data.username}`}
            href={`/users/${data.id}`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "users-delete",
      action: userDeleteAction,
    },
  ],
};
