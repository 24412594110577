import {
  Form,
  ActionFunctionArgs,
  redirect,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Input } from "../../../components/inputs/Input";
import CustomSelect from "../../../components/customSelect";
import { FormLayout } from "../../../layout/FormLayout";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import {
  useOrganizationStore,
} from "../../../store/organization";
import { useEffect, useState } from "react";
import {GET_ITEMS_BY_EVENT, GET_LIST_EVENTS_BY_ORGANIZATION} from "../ticket-search/queries";
import { useUserStore } from "../../../store/user";
import {nanoid} from "nanoid";
import {PhoneInput} from "react-international-phone";
import {TicketingItem} from "../../../types/tickets";

const client = createApolloClient();
type Event = {
  name: string;
  id: string;
};

type EventName = {
  value: number;
  label: string;
};
type SelectedItem = TicketingItem & { quantity: number };

export const ticketCompCreateAction = async ({ request }: ActionFunctionArgs) => {
  try {
    const body = await request.formData();
    const referenceId = nanoid(16);

    let items: any[] = [];
    const itemsJson = body.get("selectedItems");

    if (typeof itemsJson === "string" && itemsJson.trim() !== "") {
      try {
        items = JSON.parse(itemsJson);
      } catch (parseError) {
        toast.error("Invalid items JSON format");
        return redirect(`/tickets/ticket-comp`);
      }
    }

    const data = {
      order: {
        digital_surcharge: 0,
        email: body.get("email") || null,
        subtotal: 0,
        eventId: body.get("event_id") || null,
        eventName: body.get("event_name"),
        tax: 0,
        promo_code_uid: null,
        total_promo_amount: 0,
        transaction_total: 0,
      },
      items,
      customerPayload: {
        customerName: body.get("customerName") || null,
        customerBusinessName: body.get("customerBusinessName") || null,
        email: body.get("email") || null,
        customerPhone: body.get("customerPhone") || null,
        customerAddress1: body.get("customerAddress1") || null,
        customerAddress2: body.get("customerAddress2") || null,
        customerCity: body.get("customerCity") || null,
        customerState: body.get("customerState") || null,
        customerZipcode: body.get("customerZipcode") || null,
      },
      result: {
        id: "comped-ticket",
      },
      referenceId,
    };
    console.log({data})
    const response = await fetch(import.meta.env.VITE_RONIN_API_TICKET_COMP_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.success) {
      const referenceID = result.referenceID
      const rfidAssetIDs = result.rfidAssetIDs
      const order = data.order;
      const customerPayload = data.customerPayload;
      const items = data.items;
      const emailPayload = {
        referenceId: referenceID,
        rfidAssetIds: rfidAssetIDs,
        order,
        customerDetails: customerPayload,
        items
      }
      const emailResult = await fetch(import.meta.env.VITE_RONIN_API_SEND_MAIL_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      });
      toast.success("Ticket Comp Created");
      return redirect(`/tickets/list`);
    } else {
      toast.error("Failed to create Ticket Comp");
      return redirect(`/tickets/ticket-comp`);
    }
  } catch (error: any) {
    const errorType = error?.graphQLErrors?.[0]?.extensions?.code;
    if (errorType === "constraint-violation") {
      toast.error("Ticket Comp Already Created");
      return redirect(`/tickets/ticket-comp`);
    } else {
      toast.error("Unable to create Ticket Comp: " + error.message);
      return redirect(`/tickets/ticket-comp`);
    }
  }
};


export const TicketCompCreate = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizations = useOrganizationStore((state) => state.organizations);
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const user = useUserStore((state) => state.user);
  const [selectedOrg, setSelectedOrg] = useState(organizationId);
  const [eventOptions, setEventOptions] = useState<EventName[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<number | null>();
  const [selectedEventName, setSelectedEventName] = useState<string | null>();
  const [itemOptions, setItemOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [email, setEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  useEffect(() => {
    const fetchEvents = async () => {
      if (selectedOrg) {
        try {
          let where: any = {
            organization_id: { _eq: selectedOrg },
          };
          if (user?.events) {
            where = {
              ...where,
              id: {
                _in: user?.events,
              },
            };
          }
          const { data } = await client.query({
            query: GET_LIST_EVENTS_BY_ORGANIZATION,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          });

          if (data && data.events) {
            const newEventOptions = data.events.map((event: Event) => ({
              label: event.name,
              value: event.id,
            }));
            setEventOptions(newEventOptions);
            setSelectedEventId(null);
          }
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      } else {
        setEventOptions([]);
      }
    };
    fetchEvents();
  }, [selectedOrg]);

  useEffect(() => {
    const fetchItems = async () => {
      if (selectedEventId) {
        try {
          let where: any = {
            event_id: { _in: selectedEventId },
            is_active: { _eq: true}
          };
          const { data } = await client.query({
            query: GET_ITEMS_BY_EVENT,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          });
          setItemOptions(data.items_ticketing)
        } catch (error) {
          console.error("Error fetching items:", error);
        }
      } else {
        setItemOptions([]);
      }
    };
    fetchItems();
  }, [selectedEventId]);

  const handleEventSelect = (eventId: number) => {
    const selectedEventOption = eventOptions.find((event: EventName) => event.value === eventId);
    setSelectedEventId(eventId);
    setSelectedEventName(selectedEventOption ? selectedEventOption.label : null);
  };


  const handleQuantityChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      item: TicketingItem
  ) => {
    let quantity = parseInt(e.target.value);

    const maxQuantity =
        item.order_quantity_limit && item.sales_limit
            ? Math.min(item.order_quantity_limit, item.sales_limit)
            : item.order_quantity_limit || item.sales_limit || undefined;

    if (maxQuantity !== undefined && quantity > maxQuantity) {
      quantity = maxQuantity;
    }

    if (quantity >= 0) {
      setSelectedItems((prevItems) => {
        const existingItem = prevItems.find(
            (selected) => selected.id === item.id
        );

        if (existingItem) {
          if (quantity === 0) {
            return prevItems.filter((selected) => selected.id !== item.id);
          } else {
            return prevItems.map((selected) =>
                selected.id === item.id ? { ...selected, quantity } : selected
            );
          }
        } else {
          return quantity > 0 ? [...prevItems, { ...item, quantity }] : prevItems;
        }
      });
    }
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (!email || !confirmEmail) {
      event.preventDefault();
      toast.error("Please enter and confirm your email.");
      return;
    }

    if (email !== confirmEmail) {
      event.preventDefault(); // Prevent form submission
      toast.error("Emails do not match. Please enter the same email in both fields.");
    }
  };

  console.log(itemOptions)
  return (
    <Form action="/tickets/ticket-comp" method="post" onSubmit={handleSubmit}>
      <FormLayout>
        {permissions === "admin" && (
            <div className="col-span-6">
              <CustomSelect
                  title="Organization"
                  name="organization_id"
                  required
                  defaultValue={organizationId}
                  onChange={(v) => {
                    setSelectedOrg(v);
                    setEventOptions([]);
                    setSelectedEventId(null);
                  }}
                  options={organizations.map((org) => ({
                    label: org.name,
                    value: org.id,
                  }))}
              />
            </div>
        )}
        {permissions !== "admin" && (
            <input
                type="hidden"
                value={organizationId ?? ""}
                name="organization_id"
            />
        )}
        <div className="col-span-6">
          <CustomSelect
              title="Event"
              name="event_id"
              required
              defaultValue={selectedEventId}
              onChange={handleEventSelect}
              options={eventOptions}
          />
        </div>
        {selectedEventId && (
            <div className="col-span-6 text-md font-medium bg-gray-100 p-2 rounded text-white">
              {itemOptions.map((item: TicketingItem) => (
                  <div
                      key={item.id}
                      className={`bg-white text-stone-700 mt-2 p-6 rounded-lg ${
                          !item.available_public_sale ? 'border-4 border-primary-600' : ''
                      }`}
                  >
                    {!item.available_public_sale && (
                        <div className="mb-2 text-red-600 font-semibold">
                          Not Available for Public Sale
                        </div>
                    )}

                    <div className="grid grid-cols-4 items-center">
                      <span><b>Ticket</b></span>
                      <span><b>Price</b></span>
                      <span className="col-start-4 text-center"><b>Quantity</b></span>

                      <span>{item.name}</span>
                      <span>
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(item.price / 100)}
      </span>

                      {item?.sales_limit_balance != null && item.sales_limit_balance < 100 && (
                          <span>{item.sales_limit_balance} Tickets Remaining!</span>
                      )}

                      {item.sales_limit_balance != null && item.sales_limit_balance === 0 ? (
                          <span className='text-center'>Sold Out!</span>
                      ) : (
                          <input
                              type="number"
                              value={
                                  selectedItems.find(
                                      (selected) => selected.id === item.id
                                  )?.quantity || 0
                              }
                              onChange={(e) => handleQuantityChange(e, item)}
                              onWheel={(e) => e.currentTarget.blur()}
                              className="col-span-1 col-start-4 text-stone-700 rounded-lg ml-2"
                              style={{ textAlign: "center" }}
                              max={
                                item.order_quantity_limit && item.sales_limit_balance
                                    ? Math.min(item.order_quantity_limit, item.sales_limit_balance)
                                    : item.order_quantity_limit ||
                                    item.sales_limit_balance ||
                                    undefined
                              }
                          />
                      )}
                    </div>

                    <div className="col-span-4 text-left bg-gray-100 mt-2 rounded-lg">
                      {item.description ? (
                          <div className="prose custom-quill text-gray-700 prose-sm" dangerouslySetInnerHTML={{ __html: item.description }} />
                      ) : (
                          <p className="ml-5">No description available</p>
                      )}
                    </div>
                  </div>
              ))}
            </div>
        )}
        <div className="col-span-3">
          <Input label="Customer Name" name="customerName" required/>
        </div>
        <div className="col-span-3">
          <Input label="Business Name (Optional)" name="customerBusinessName"/>
        </div>
        <div className="col-span-3">
          <Input
              label="Email"
              name="email"
              type="email"
              required
              value={email}
              onChange={(value) => setEmail(value as string)}
          />
        </div>
        <div className="col-span-3">
          <Input
              label="Confirm Email"
              name="emailConfirm"
              type="email"
              required
              value={confirmEmail}
              onChange={(value) => setConfirmEmail(value as string)}
          />
        </div>
        <div className="col-span-6">
          <label
              htmlFor="phone"
              className="block text-md mb-1 text-sm text-left font-medium text-gray-700"
          >
            Phone Number
          </label>
          <PhoneInput name="customerPhone"/>
        </div>
        <div className="col-span-3">
          <Input label="Customer Address" name="customerAddress1"/>
        </div>
        <div className="col-span-3">
          <Input label="Customer Address 2 (Optional)" name="customerAddress2"/>
        </div>
        <div className="col-span-3">
          <Input label="Customer City" name="customerCity"/>
        </div>
        <div className="col-span-1">
          <Input label="Customer State" name="customerState"/>
        </div>
        <div className="col-span-2">
          <Input label="Customer Zipcode" name="customerZipcode"/>
        </div>
        <input
            type="hidden"
            value={JSON.stringify(selectedItems)}
            name="selectedItems"
        />
        <input type="hidden" name="event_name" value={selectedEventName || ""}/>
      </FormLayout>
    </Form>
  );
};
