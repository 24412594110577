import { gql } from "@apollo/client";

export const CREATE_TICKETING_ITEM = gql`
  mutation create_item_ticketing($input: items_ticketing_insert_input!) {
    insert_items_ticketing_one(object: $input) {
      id
    }
  }
`;

export const UPDATE_TICKETING_ITEM = gql`
  mutation update_items_ticketing($input: items_ticketing_set_input, $where: items_ticketing_bool_exp!) {
    update_items_ticketing(_set: $input, where: $where) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_TICKETING_ITEM = gql`
   mutation DeleteTicketingItem($id: Int!) {
    delete_items_ticketing_by_pk(id: $id) {
      id
    }
  }
`;
