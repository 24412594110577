import { useState } from "react";
import toast from "react-hot-toast";
import {
  Form,
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  LoaderFunctionArgs,
} from "react-router-dom";
import { Input } from "../../components/inputs/Input";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { useOrganizationStore } from "../../store/organization";
import { Vendor, VendorType } from "../../types/vendor";
import { CREATE_VENDOR } from "./mutations";
import { VENDOR_BY_ID_QUERY, VENDOR_CATAGORIES } from "./queries";
import CustomSelect from "../../components/customSelect";
import { Categories } from "../../types/item";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import PercentageInput from "../../components/inputs/PercentageInput";
import PriceInput from "../../components/inputs/PriceInput";
import { userStore } from "../../store/user";
import { organizationStore } from "../../store/organization";

const client = createApolloClient();
const { getState: getUserStore } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const cloneVendorLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const organizationId = getOrganizationStore().organizationId;
  const { data } = await client.query<{ vendors_by_pk: Vendor }>({
    query: VENDOR_BY_ID_QUERY,
    variables: { id },
  });

  const { data: vendorTypeData } = await client.query({
    query: VENDOR_CATAGORIES,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "vendor_type",
            },
          }
        : {},
    },
  });
  return {
    vendor: data.vendors_by_pk,
    vendorType: vendorTypeData.dashboard_reference,
  };
};

export const cloneVendorAction = async ({ request }: ActionFunctionArgs) => {
  const user = getUserStore().user;
  const body = await request.formData();
  const inputData = Object.fromEntries(body);
  const name = inputData.name as string;
  const organization_id = inputData.organization_id as string;
  const type = inputData.type as string;
  const revenue_share = inputData.revenue_share as string;
  const flat_fee = inputData.flat_fee as string;

  if (!name || !type || !organization_id) {
    toast.error("Please fill out all required fields.");
    return null;
  }

  try {
    const revenueSharePer = (parseFloat(revenue_share || "0") / 100).toFixed(2);
    await client.mutate({
      mutation: CREATE_VENDOR,
      variables: {
        input: {
          ...inputData,
          flat_fee: flat_fee ? +flat_fee * 100 : null,
          revenue_share: revenueSharePer,
          last_updated_by: user?.id,
        },
      },
    });
    toast.success("Vendor Cloned");
    return redirect("/vendors/list");
  } catch (error) {
    return toast.error("Failed to clone vendor: " + error);
  }
};

export const VendorClone = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const { vendor, vendorType } = useLoaderData() as {
    vendor: Vendor;
    vendorType: Categories[];
  };

  const organizations = useOrganizationStore((state) => state.organizations);
  const [type, setType] = useState<string | null>(vendor.type);
  const [flatFee, setFlatFee] = useState(vendor.flat_fee / 100);
  const clonedVendorName = `${vendor.name} - clone`;

  const handlePriceChange = (value: number) => {
    setFlatFee(value);
  };
  return (
    <>
      <Form method="post" action={`/vendors/${vendor.id}/clone`}>
        <FormLayout>
          <div className="col-span-6">
            <Input
              label="Name"
              name="name"
              defaultValue={clonedVendorName}
              required
            />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
              name="is_active"
              label="Is Active"
              defaultChecked={vendor.is_active}
            />
          </div>
          {permissions === "admin" && (
            <div className="col-span-6">
              <CustomSelect
                title="Organization"
                name="organization_id"
                required
                defaultValue={vendor.organization_id}
                options={organizations.map((org) => ({
                  label: org.name,
                  value: org.id,
                }))}
              />
            </div>
          )}
          {permissions !== "admin" && (
            <input
              type="hidden"
              value={vendor.organization_id ?? ""}
              name="organization_id"
            />
          )}
          <div className="col-span-6">
            <CustomSelect
              title="Vendor Type"
              name="type"
              required
              defaultValue={type}
              onChange={(v) => setType(v)}
              options={[
                ...(vendorType?.map((v) => ({
                  label: v.reference_label,
                  value: v.reference_value,
                })) || []),
              ]}
            />
          </div>
          {type === VendorType.OTHER && (
            <div className="col-span-6">
              <Input
                label="Other Type"
                name="other_type"
                required
                defaultValue={vendor.other_type}
              />
            </div>
          )}
          <div className="col-span-6">
            <Input
              label="Point-of-Contact Name"
              name="poc_name"
              defaultValue={vendor.poc_name}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Point-of-Contact E-mail"
              name="poc_email"
              defaultValue={vendor.poc_email}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Point-of-Contact Phone Number"
              name="poc_phone"
              defaultValue={vendor.poc_phone}
            />
          </div>
          <div className="col-span-4">
            <PercentageInput
              label="Revenue Share"
              name="revenue_share"
              defaultValue={((vendor?.revenue_share || 0) * 100).toFixed(2)}
            />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
              name="sales_tax_responsible"
              label="Sales Tax Responsible"
              defaultChecked={vendor?.sales_tax_responsible || false}
            />
          </div>
          <div className="col-span-6">
            <PriceInput
              value={flatFee}
              required
              label="Flat Fee"
              name="flat_fee"
              onChange={(value) => handlePriceChange(value ? value : 0)}
            />
          </div>
        </FormLayout>
      </Form>
    </>
  );
};
