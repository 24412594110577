import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../store/organization";
import { Item } from "../../../types/item";
import { usdFormat } from "../../../utils";
import { GET_TICKETING_ITEMS } from "./queries";
import { Badge } from "../../../components/Badge";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../../store/event";
import moment from "moment";
import { usePermissions } from "../../auth/api";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { Input } from "../../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { userStore } from "../../../store/user";
import Switch from "react-switch";
import {TicketingItem} from "../../../types/tickets";
// import ItemImportCSVModal from "../../components/importCsv/ItemImportCSVModal";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventStore } = eventStore;

const buildSearch = (search: string) => [
  {
    name: {
      _ilike: `%${search}%`,
    },
  },
  {
    category: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: Item;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: Item }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }
    return (
      <>
        {permission ? (
          <Link to={linkFunc(params)}>
            <Badge>{params.value}</Badge>
          </Link>
        ) : (
          <Badge>{params.value}</Badge>
        )}
      </>
    );
  };
};
export const ticketingItemsListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(
    rawOrganizationId !== null ? rawOrganizationId.toString() : "",
    10
  );
  const eventId = getEventStore().eventId;
  const user = getState().user;

  let where: any = {
    _or: search ? buildSearch(search) : undefined,
  };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      event: {
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }
  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    where = {
      ...where,
      event_id: {
        _in: user?.events,
      },
    };
  }
  const { data } = await client.query({
    query: GET_TICKETING_ITEMS,
    variables: {
      where,
    },
  });
  return data.items_ticketing;
};

export const TicketingItemList = () => {
  const { ItemsPermission } = usePermissions("ticketingReporting");
  const { editCreatePermission: eventsEditPermission } =
    usePermissions("events");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const items = useLoaderData() as TicketingItem[];
  const [rowData, setRowData] = useState<TicketingItem[]>([]);
  const [isActiveToggle, setIsActiveToggle] = useState(true);
  useEffect(() => {
    const filteredItems  = isActiveToggle
        ? items.filter((item) => item.is_active)
        : items;
    setRowData(filteredItems);
  }, [items, isActiveToggle]);
  const generateColDefs = (): ColDef<TicketingItem>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
          ItemsPermission,
        (p) => `/ticketing-items/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Item Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: createCellRenderer(
          ItemsPermission,
        (p) => `/ticketing-items/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Price",
      valueGetter: (p) => p.data?.price,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Tax Percentage",
      valueGetter: (p) => p.data?.tax_percentage,
      valueFormatter: (params) => {
        let value = parseFloat(params.value);

        if (isNaN(value)) {
          value = 0;
        }

        return value.toFixed(6);
      },
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Category",
      valueGetter: (p) => p.data?.category,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Event",
      valueGetter: (p) => p.data?.event?.name,
      filter: "agTextColumnFilter",
      cellRenderer: createCellRenderer(
        eventsEditPermission,
        (p) => `/events/${p.data?.event?.id}/edit`
      ),
    },
    {
      headerName: "Sales Limit",
      valueGetter: (p) => p.data?.sales_limit,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Tickets Remaining",
      valueGetter: (p) => p.data?.sales_limit_balance,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Order Quantity Limit",
      valueGetter: (p) => p.data?.order_quantity_limit,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Unlimited Ingress?",
      valueGetter: (p) => p.data?.unlimited_ingress,
      valueFormatter: (p) => {
        return p.data?.unlimited_ingress ? "Yes" : "No";
      },
    },
    {
      headerName: "Include Egress?",
      valueGetter: (p) => p.data?.include_egress,
      valueFormatter: (p) => {
        return p.data?.include_egress ? "Yes" : "No";
      },
    },
    {
      headerName: "Include POS Tokens?",
      valueGetter: (p) => p.data?.include_pos_tokens,
      valueFormatter: (p) => {
        return p.data?.include_pos_tokens ? "Yes" : "No";
      },
    },
    // {
    //   headerName: "Start Date",
    //   valueGetter: (p) => p.data?.on_sale_date,
    //   valueFormatter: (p) => {
    //     const date = p.value ? moment(p.value) : null;
    //     return date ? date.format("MM/DD/YYYY") : "";
    //   },
    // },
    // {
    //   headerName: "End Date",
    //   valueGetter: (p) => p.data?.off_sale_date,
    //   valueFormatter: (p) => {
    //     const date = p.value ? moment(p.value) : null;
    //     return date ? date.format("MM/DD/YYYY") : "";
    //   },
    // },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<TicketingItem>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Items_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs/>
          <div className="col-span-1 col-start-4 mt-3 flex flex-col items-center">
            <span className="block text-sm font-medium text-gray-500">Active Only</span>
            <Switch
                checked={isActiveToggle}
                onChange={() => setIsActiveToggle((prev) => !prev)}
                onColor="#EE2E30"
                onHandleColor="#FFFFFF"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={48}
            />
          </div>
          <div className="col-span-2 col-start-5 mt-2">
            <Input
                name="search"
                placeholder={`Search Ticketing Items`}
                onChange={(s) => {
                  navigate(
                      s && typeof s === "string" && s.length > 0
                          ? `/ticketing-items/list?search=${s}`
                          : `/ticketing-items/list`
                  );
                  revalidator.revalidate();
                }}
            />
          </div>

          <div className="py-2 col-span-2 col-start-7">
            {/*<ItemImportCSVModal*/}
            {/*  // user={user}*/}
            {/*  onConfirm={() => {*/}
            {/*    // fetcher.submit(*/}
            {/*    //   {},*/}
            {/*    //   {*/}
            {/*    //     method: "delete",*/}
            {/*    //     action: `/menus/${menu.id}/delete`,*/}
            {/*    //   }*/}
            {/*    // );*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {({ setOpen }) => (*/}
            {/*    <PrimaryButton onClick={() => setOpen(true)}>*/}
            {/*      Import CSV*/}
            {/*    </PrimaryButton>*/}
            {/*  )}*/}
            {/*</ItemImportCSVModal>*/}
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {ItemsPermission && (
                <PrimaryButton onClick={() => navigate(`/ticketing-items/create`)}>
                  Create
                </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            gridOptions={gridOptions}
            paginationPageSize={100}
            onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
