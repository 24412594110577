import {
    ActionFunctionArgs,
    Form,
    redirect,
    useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../../components/inputs/IndeterminantCheckbox";
import {Input} from "../../../components/inputs/Input";
import PriceInput from "../../../components/inputs/PriceInput";
import {FormLayout} from "../../../layout/FormLayout";
import {createApolloClient} from "../../../providers/ApolloClientFactory";
import { CREATE_TICKETING_ITEM } from "./mutations";
import {useState} from "react";
import {Categories} from "../../../types/item";
import {userStore} from "../../../store/user";
import CustomSelect from "../../../components/customSelect";
import {organizationStore} from "../../../store/organization";
import {GET_LIST_EVENTS_BY_ORGANIZATION} from "../../../queries";
import {Event} from "../../../types/event";
import {PrimaryButton} from "../../../components/Button";
import {GET_ITEM_CATEGORIES} from "./queries";
import TicketingItemHTML from "../components/TickingItemHTML";
import {DatePicker} from "../../../components/DatePicker";

const client = createApolloClient();
const {getState} = userStore;
const {getState: getOrganizationStore} = organizationStore;
type POSToken = {
    name: string;
    quantity: number;
};

export const ticketingItemCreateLoader = async () => {
    const organizationId = getOrganizationStore().organizationId;
    const user = getState().user;

    let eventWhere = {};

    if (user?.events) {
        eventWhere = {
            ...eventWhere,
            id: {
                _in: user?.events,
            },
        };
    }

    if (organizationId) {
        eventWhere = {
            ...eventWhere,
            organization_id: {_eq: organizationId},
            is_active: {_eq: true},
        };
    }

    const {data: eventsData} = await client.query({
        query: GET_LIST_EVENTS_BY_ORGANIZATION,
        variables: {
            where: eventWhere,
        },
    });

    const {data: itemCategoriesData} = await client.query({
        query: GET_ITEM_CATEGORIES,
        variables: {
            where: organizationId
                ? {
                    organization_id: {
                        _in: [0, organizationId],
                    },
                    is_active: {
                        _eq: true,
                    },
                    reference_type: {
                        _eq: "item_ticketing_category",
                    },
                }
                : {},
        },
    });
    return {
        events: eventsData.events,
        itemCategories: itemCategoriesData.dashboard_reference,
    };
};
export const ticketingItemCreateAction = async ({
                                                    params,
                                                    request,
                                                }: ActionFunctionArgs) => {
    const body = await request.formData();
    const user = getState().user;
    const priceString = body.get("price") as string;
    const price = parseFloat(priceString.length ? priceString : "0");
    const taxPercentageString = body.get("tax_percentage") as string;
    const taxPercentage = (parseFloat(taxPercentageString) / 100).toFixed(8);
    // const onSaleDate = body.get("on_sale_date") as string !== "" ? body.get("on_sale_date") : null;
    // const offSaleDate = body.get("off_sale_date") as string !== "" ? body.get("off_sale_date") : null;
    const posTokens = JSON.parse(body.get("posTokens") as string);
    const includePOSTokens = body.get("include_additional_pos_tokens") as string
    const includeEgressToken = body.get("include_egress") as string
    const unlimitedIngress = body.get("unlimited_ingress") as string
    let tokenPayload = {} as Record<
        string,
        { balance: number; redeemable_token_id: string; redeemable_token_name: string }
    >;
    const itemName = body.get("name") as string;
    const ingressTokenQuantityStr = body.get("ingressTokenQuantity") as string;
    const ingressTokenQuantity = parseInt(ingressTokenQuantityStr, 10) || 0;
    const salesLimitStr = body.get("sales_limit") as string;
    const salesLimit = parseInt(salesLimitStr, 10) || null;
    const orderQuantityLimitStr = body.get("order_quantity_limit") as string;
    const orderQuantityLimit = parseInt(orderQuantityLimitStr, 10) || null;
    tokenPayload[itemName] = {
        balance: ingressTokenQuantity,
        redeemable_token_id: itemName,
        redeemable_token_name: itemName,
    };
    if (includeEgressToken === "true"){
        console.log("Including Egress Token")
        const egressName = itemName + " - Egress";
        tokenPayload[egressName] = {
            balance: 1000,
            redeemable_token_id: egressName,
            redeemable_token_name: egressName,
        };
    }

    if (includePOSTokens === "true") {
        posTokens.forEach((token: { name: string; quantity: number }) => {
            if (token.name.trim() !== "") {
                tokenPayload[token.name] = {
                    balance: token.quantity,
                    redeemable_token_id: token.name,
                    redeemable_token_name: token.name,
                };
            }
        });
    }

    let input = {
        name: body.get("name"),
        is_active: body.get("is_active"),
        event_id: body.get("event_id"),
        category: body.get("category"),
        description: body.get("description"),
        additional_email_description: body.get("additional_email_description"),
        price: Math.round((price) * 100),
        tax_percentage: taxPercentage ? taxPercentage : 0,
        last_updated_by: user?.id,
        token_payload: tokenPayload,
        unlimited_ingress: unlimitedIngress,
        include_egress: includeEgressToken,
        pos_token_payload: posTokens,
        include_pos_tokens: includePOSTokens,
        sales_limit: salesLimit,
        sales_limit_balance: salesLimit,
        order_quantity_limit: orderQuantityLimit,
        // on_sale_date: onSaleDate,
        // off_sale_date: offSaleDate,
        available_public_sale: body.get("available_public_sale"),
    };

    try {
        await client.mutate({
            mutation: CREATE_TICKETING_ITEM,
            variables: {
                input,
            },
        });

        toast.success("Ticketing Item created");

        return redirect(`/ticketing-items/list`);
    } catch (error: any) {
        const errorType = error?.graphQLErrors?.[0]?.extensions?.code;
        const message = error?.graphQLErrors?.[0]?.message;
        if (errorType === "constraint-violation") {
            toast.error(message);
        } else {
            toast.error("Failed to create ticketing item" + error);
        }
        return redirect("/ticketing-items/create");
    }
};

export const TicketingItemCreate = () => {
    const [ingressTokenQuantity, setIngressTokenQuantity] = useState(1);
    const [includePOSTokens, setIncludePOSTokens] = useState(false);
    const [posTokens, setPOSTokens] = useState<POSToken[]>([{ name: "", quantity: 0 }]);
    const [price, setPrice] = useState<number>(0);
    const [content, setContent] = useState<string>("");
    const [additionalContent, setAdditionalContent] = useState<string>("");
    const {events, itemCategories} = useLoaderData() as {
        events: Event[];
        itemCategories: Categories[];
    };
    const handlePriceChange = (value: number) => {
        setPrice(value);
    };
    const handleAddTokenSet = () => {
        setPOSTokens([...posTokens, { name: "", quantity: 0 }]);
    };
    const handleRemoveTokenSet = () => {
        if (posTokens.length > 0) {
            setPOSTokens(posTokens.slice(0, -1));
        }
    };
    const handleTokenChange = (
        index: number,
        field: keyof POSToken,
        value: string | number | undefined
    ) => {
        const updatedTokens = [...posTokens];
        if (field === "quantity") {
            updatedTokens[index].quantity = Number(value) || 0;
        } else {
            updatedTokens[index].name = String(value);
        }
        setPOSTokens(updatedTokens);
    };

    return (
        <Form
            method="post"
            action={`/ticketing-items/create`}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
        >
            <FormLayout>
                <div className="col-span-5 sm:col-span-3">
                    <Input label="Ticketing Item Name" name="name" required/>
                </div>
                <IndeterminateCheckbox
                    label="Is Active?"
                    name="is_active"
                    defaultChecked
                />
                <div className="col-span-2">
                    <IndeterminateCheckbox
                        label="Available for Public Sale? -uncheck for Comp Items"
                        name="available_public_sale"
                        defaultChecked={true}
                    />
                </div>
                <div className="col-span-6">
                    <CustomSelect
                        title="Event"
                        name="event_id"
                        required
                        options={[
                            ...(events?.map((e) => ({
                                label: e.name,
                                value: e.id,
                            })) || []),
                        ]}
                    />
                </div>
                <div className="col-span-6">
                    <CustomSelect
                        title="Item Category"
                        name="category"
                        required
                        options={[
                            ...(itemCategories?.map((v) => ({
                                label: v.reference_label,
                                value: v.reference_value,
                            })) || []),
                        ]}
                    />
                </div>
                <div className="col-span-6">
                    <TicketingItemHTML
                        content={content}
                        setContent={setContent}
                        label="Description"
                    />
                    <Input type="hidden" name="description" value={content} />
                </div>
                <div className="col-span-6">
                    <TicketingItemHTML
                        content={additionalContent}
                        setContent={setAdditionalContent}
                        label="Additional Email Confirmation Description"
                    />
                    <Input type="hidden" name="additional_email_description" value={additionalContent} />
                </div>
                <div className="col-span-5 sm:col-span-3">
                    <PriceInput
                        label="Price"
                        name="price"
                        onChange={(value) => handlePriceChange(value ? value : 0)}
                        value={price}
                        required
                    />
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <Input
                        label="Tax % ( 7.5 = 7.5% )"
                        name="tax_percentage"
                        required
                        defaultValue={0}
                    />
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <Input
                        label="Total Ticket Quantity Limit"
                        name="sales_limit"
                        type="number"
                    />
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <Input
                        label="Order Quantity Limit"
                        name="order_quantity_limit"
                        type="number"
                    />
                </div>
                {/*<div className="col-span-2">*/}
                {/*    <DatePicker*/}
                {/*        label="On Sale Date"*/}
                {/*        name="on_sale_date"*/}
                {/*        includeTime={true}*/}
                {/*        granularity="minute"*/}
                {/*        hourCycle={12}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="col-span-2">*/}
                {/*    <DatePicker*/}
                {/*        label="Off Sale Date"*/}
                {/*        name="off_sale_date"*/}
                {/*        includeTime={true}*/}
                {/*        granularity="minute"*/}
                {/*        hourCycle={12}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="col-span-2">
                    <IndeterminateCheckbox
                        label="Unlimited Ingress?"
                        name="unlimited_ingress"
                        defaultChecked={false}
                        onChange={(checked) => setIngressTokenQuantity(checked ? 1000 : 1)}
                    />
                </div>
                <div className="col-span-4">
                    <IndeterminateCheckbox
                        label="Include Egress?"
                        name="include_egress"
                        defaultChecked={false}
                    />
                </div>
                <div className="col-span-6">
                    <IndeterminateCheckbox
                        label="Include Point-Of-Sale Tokens?"
                        name="include_additional_pos_tokens"
                        onChange={(checked) => {
                            setIncludePOSTokens(checked as boolean)
                            if (!checked) {
                                setPOSTokens([{name: "", quantity: 0}])
                            }
                        }}
                    />
                </div>
                {includePOSTokens && (
                    <div className="col-span-6">
                        {posTokens.map((token, index) => (
                            <div key={index} className="grid grid-cols-2 gap-4">
                                <Input
                                    label="Token Name"
                                    name={`pos_tokens[${index}][name]`}
                                    value={token.name}
                                    onChange={(value) => handleTokenChange(index, "name", String(value))} // Ensure it's a string
                                    required
                                />
                                <Input
                                    label="Token Quantity"
                                    name={`pos_tokens[${index}][quantity]`}
                                    value={token.quantity}
                                    type="number"
                                    onChange={(value) => handleTokenChange(index, "quantity", Number(value) || 0)} // Ensure it's a number
                                    required
                                />
                            </div>
                        ))}
                        <div className="mt-2">
                            <PrimaryButton type="button" onClick={handleAddTokenSet}>
                                + Add Token
                            </PrimaryButton>
                            {posTokens.length > 0 && (
                                <PrimaryButton type="button" onClick={handleRemoveTokenSet}>
                                    - Remove Token
                                </PrimaryButton>
                            )}
                        </div>
                    </div>
                )}
                <input
                    type="hidden"
                    name="posTokens"
                    value={JSON.stringify(posTokens)}
                />
                <input
                    type="hidden"
                    value={ingressTokenQuantity}
                    name="ingressTokenQuantity"
                />
            </FormLayout>
        </Form>
    );
};
