import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  Form,
  LoaderFunctionArgs,
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useFetcher,
  Link,
  useNavigate,
} from "react-router-dom";
import { Input } from "../../components/inputs/Input";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { useOrganizationStore } from "../../store/organization";
import { Vendor, VendorType } from "../../types/vendor";
import { UPDATE_VENDOR } from "./mutations";
import {
  ITEMS_BY_VENDOR_ID_ITEMS,
  LOCATION_BY_VENDOR_ID_QUERY,
  MENUS_BY_VENDOR_ID_QUERY,
  VENDOR_BY_ID_QUERY,
  VENDOR_CATAGORIES,
} from "./queries";
import CustomSelect from "../../components/customSelect";
import { usePermissions } from "../auth/api";
import DeleteModal from "../../components/DeleteModal";
import {SecondaryButton, TertiaryButton} from "../../components/Button";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Location, LocationMenus } from "../../types/location";
import { Badge } from "../../components/Badge";
import moment from "moment/moment";
import { LocationMenu, Menu } from "../../types/menu";
import { Item, Categories } from "../../types/item";
import { usdFormat } from "../../utils";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import PercentageInput from "../../components/inputs/PercentageInput";
import PriceInput from "../../components/inputs/PriceInput";
import { userStore } from "../../store/user";
import { organizationStore } from "../../store/organization";

const client = createApolloClient();
const { getState: getUserStore } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const editVendorLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const organizationId = getOrganizationStore().organizationId;
  const { data } = await client.query<{ vendors_by_pk: Vendor }>({
    query: VENDOR_BY_ID_QUERY,
    variables: { id },
  });

  const { data: locationData } = await client.query<{
    locations: Location;
  }>({
    query: LOCATION_BY_VENDOR_ID_QUERY,
    variables: { id },
  });

  const { data: menuData } = await client.query<{
    menus: Menu;
  }>({
    query: MENUS_BY_VENDOR_ID_QUERY,
    variables: { id },
  });

  const { data: itemsData } = await client.query<{
    items: Item;
  }>({
    query: ITEMS_BY_VENDOR_ID_ITEMS,
    variables: { id },
  });

  const { data: vendorTypeData } = await client.query({
    query: VENDOR_CATAGORIES,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "vendor_type",
            },
          }
        : {},
    },
  });
  return {
    vendor: data.vendors_by_pk,
    location: locationData.locations,
    menus: menuData.menus,
    items: itemsData.items,
    vendorType: vendorTypeData.dashboard_reference,
  };
};

export const editVendorAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const { id } = params;
  const user = getUserStore().user;

  const data = await request.formData();
  try {
    const revenueShare = (data.get("revenue_share") || "0") as string;
    const revenueSharePer = (parseFloat(revenueShare) / 100).toFixed(2);

    const flatFee = data.get("flat_fee");
    await client.mutate({
      mutation: UPDATE_VENDOR,
      variables: {
        id,
        data: {
          name: data.get("name"),
          type: data.get("type"),
          other_type: data.get("other_type"),
          organization_id: data.get("organization_id"),
          poc_name: data.get("poc_name"),
          poc_phone: data.get("poc_phone"),
          poc_email: data.get("poc_email"),
          is_active: data.get("is_active"),
          revenue_share: revenueSharePer,
          sales_tax_responsible: data.get("sales_tax_responsible"),
          flat_fee: flatFee ? +flatFee * 100 : null,
          last_updated_by: user?.id,
        },
      },
    });
    toast.success("Vendor updated");
    return redirect(`/vendors/list`);
  } catch (error) {
    toast.error("Error updating vendor: " + error);
  }
};

export const VendorEdit = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const { vendor, location, menus, items, vendorType } = useLoaderData() as {
    vendor: Vendor;
    location: Location[];
    menus: Menu[];
    items: Item[];
    vendorType: Categories[];
  };
  const [rowData, setRowData] = useState<Location[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    setRowData(location);
  }, [location]);
  const organizations = useOrganizationStore((state) => state.organizations);
  const [type, setType] = useState<string | null>(vendor.type);
  const [flatFee, setFlatFee] = useState(vendor.flat_fee / 100);
  const { deletePermission } = usePermissions("vendors");
  const fetcher = useFetcher();
  const gridRef = useRef<GridApi>();
  const { editCreatePermission: editCreatePermissionLocation } =
    usePermissions("locations");
  const { editCreatePermission: eventsEditPermission } =
    usePermissions("events");
  const { editCreatePermission: itemsEditPermission } = usePermissions("items");
  const { editCreatePermission: paymentProcessorEditPermission } =
    usePermissions("payment_processors");
  const { editCreatePermission: menuEditPermission } = usePermissions("menus");
  const createMultipleLinksCellRenderer = (
    permission: boolean,
    linkFunc: (locationMenu: LocationMenus) => string
  ): CellRenderer => {
    return (params) => {
      const menus = params.data?.location_menus?.map((locationMenus, index) => (
        <span key={index}>
          {permission ? (
            <Link
              to={linkFunc({ ...locationMenus, menus: locationMenus.menu })}
            >
              <Badge>{locationMenus.menu.name}</Badge>
            </Link>
          ) : (
            <Badge>{locationMenus.menu.name}</Badge>
          )}
          {index < (params?.data?.location_menus?.length ?? 0) - 1 && "  "}
        </span>
      ));
      return <>{menus}</>;
    };
  };

  type CellRenderer = (params: {
    value:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    data: Location;
  }) => JSX.Element | null;

  const createCellRenderer = (
    permission: boolean,
    linkFunc: (params: { data: Location }) => string
  ): CellRenderer => {
    return (params) => {
      if (params.value === undefined) {
        return null;
      }
      return (
        <>
          {permission ? (
            <Link to={linkFunc(params)}>
              <Badge>{params.value}</Badge>
            </Link>
          ) : (
            <Badge>{params.value}</Badge>
          )}
        </>
      );
    };
  };
  const locationGenerateColDefs = (): ColDef<Location>[] => [
    {
      headerName: "Location Name",
      valueGetter: (p) => {
        console.log({ p });
        return p.data?.name;
      },
      cellRenderer: createCellRenderer(
        editCreatePermissionLocation,
        (p) => `/locations/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Location Type",
      valueGetter: (p) => p.data?.location_type,
    },
    {
      headerName: "Event Name",
      valueGetter: (p) => p.data?.event?.name,
      cellRenderer: createCellRenderer(
        eventsEditPermission,
        (p) => `/events/${p.data?.event?.id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Payment Processor",
      valueGetter: (p) => p.data?.payment_processor_config?.payment_processor,
      cellRenderer: createCellRenderer(
        paymentProcessorEditPermission,
        (p) =>
          `/payment-processors/${p.data?.payment_processor_config?.id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Service Fee %",
      valueGetter: (p) => p.data?.digital_surcharge_percentage,
      valueFormatter: (params) => {
        const value = params.value ?? 0;
        return value !== 0 ? `${value}%` : "";
      },
    },
    {
      headerName: "Dynamic Descriptor",
      valueGetter: (p) => p.data?.dynamic_descriptor,
    },
    {
      headerName: "Menu(s) Name",
      valueGetter: (p) => p.data?.location_menus,
      valueFormatter: (params) => {
        console.log(params.value);
        const menus: LocationMenus[] = params.value || [];
        return menus.map((menu) => menu?.menus?.name).join(", ");
      },
      cellRenderer: createMultipleLinksCellRenderer(
        menuEditPermission,
        (locationMenus) => `/menus/${locationMenus?.menus?.id}/edit`
      ),
      filter: false,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const locationColDefs: ColDef<Location>[] = locationGenerateColDefs();

  type MenuCellRenderer = (params: {
    value:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    data: Menu;
  }) => JSX.Element;

  const createMenuMultipleLinksCellRenderer = (
    permission: boolean,
    linkFunc: (locationMenu: LocationMenu) => string
  ): MenuCellRenderer => {
    return (params) => {
      const locations = params.data?.location_menus?.map(
        (locationMenu, index) => (
          <span key={index}>
            {permission ? (
              <Link to={linkFunc(locationMenu)}>
                <Badge>{locationMenu.location.name}</Badge>
              </Link>
            ) : (
              <Badge>{locationMenu.location.name}</Badge>
            )}
            {index < (params?.data?.location_menus?.length ?? 0) - 1 && " "}
          </span>
        )
      );
      return <>{locations}</>;
    };
  };
  const menuGenerateColDefs = (): ColDef<Menu>[] => [
    {
      headerName: "Menu Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: createCellRenderer(
        menuEditPermission,
        (p) => `/menus/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Event Name",
      valueGetter: (p) => p.data?.event.name,
      cellRenderer: createCellRenderer(
        eventsEditPermission,
        (p) => `/events/${p.data.event_id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Location(s) Name",
      valueGetter: (p) => p.data?.location_menus,
      valueFormatter: (params) => {
        const locations: LocationMenu[] = params.value || [];
        return locations.map((location) => location.location.name).join(", ");
      },
      cellRenderer: createMenuMultipleLinksCellRenderer(
        editCreatePermissionLocation,
        (locationMenu) => `/locations/${locationMenu.location.id}/edit`
      ),
      filter: false,
    },
    {
      headerName: "Tax Type",
      valueGetter: (p) => p.data?.tax_type,
      valueFormatter: (p) => {
        const taxType = p.data?.tax_type || "";
        return taxType.charAt(0).toUpperCase() + taxType.slice(1);
      },
    },
    {
      headerName: "Cash?",
      valueGetter: (p) => p.data?.is_cash,
      valueFormatter: (p) => {
        return p.data?.is_cash ? "Yes" : "No";
      },
    },
    {
      headerName: "Credit?",
      valueGetter: (p) => p.data?.is_credit,
      valueFormatter: (p) => {
        return p.data?.is_credit ? "Yes" : "No";
      },
    },
    {
      headerName: "RFID?",
      valueGetter: (p) => p.data?.is_rfid,
      valueFormatter: (p) => {
        return p.data?.is_rfid ? "Yes" : "No";
      },
    },
    {
      headerName: "QR?",
      valueGetter: (p) => p.data?.is_qr,
      valueFormatter: (p) => {
        return p.data?.is_qr ? "Yes" : "No";
      },
    },
    {
      headerName: "Tips?",
      valueGetter: (p) => p.data?.is_tips,
      valueFormatter: (p) => {
        return p.data?.is_tips ? "Yes" : "No";
      },
    },
    {
      headerName: "Custom Items?",
      valueGetter: (p) => p.data?.is_custom_item,
      valueFormatter: (p) => {
        return p.data?.is_custom_item ? "Yes" : "No";
      },
    },
    {
      headerName: "Discounts?",
      valueGetter: (p) => p.data?.is_discount,
      valueFormatter: (p) => {
        return p.data?.is_discount ? "Yes" : "No";
      },
    },
    {
      headerName: "Discounts Protected?",
      valueGetter: (p) => p.data?.is_discount_protected,
      valueFormatter: (p) => {
        return p.data?.is_discount_protected ? "Yes" : "No";
      },
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      field: "updated_at", // Assuming 'updated_at' is the field name
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const menuColDefs: ColDef<Menu>[] = menuGenerateColDefs();

  type ItemCellRenderer = (params: {
    value:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    data: Item;
  }) => JSX.Element | null;
  const itemCreateCellRenderer = (
    permission: boolean,
    linkFunc: (params: { data: Item }) => string
  ): ItemCellRenderer => {
    return (params) => {
      if (params.value === undefined) {
        return null;
      }
      return (
        <>
          {permission ? (
            <Link to={linkFunc(params)}>
              <Badge>{params.value}</Badge>
            </Link>
          ) : (
            <Badge>{params.value}</Badge>
          )}
        </>
      );
    };
  };

  const itemGenerateColDefs = (): ColDef<Item>[] => [
    {
      headerName: "Item Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: itemCreateCellRenderer(
        itemsEditPermission,
        (p) => `/items/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Price",
      valueGetter: (p) => p.data?.price,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
    },
    {
      headerName: "Tax Percentage",
      valueGetter: (p) => p.data?.tax_percentage,
      valueFormatter: (params) => {
        const value = params.value ?? 0;
        return value.toFixed(6); // Render value to 6 decimal places
      },
    },
    {
      headerName: "Category",
      valueGetter: (p) => p.data?.tags,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Token Name",
      valueGetter: (p) => p.data?.redeemable_token_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Event",
      valueGetter: (p) => p.data?.event?.name,
      filter: "agTextColumnFilter",
      cellRenderer: itemCreateCellRenderer(
        eventsEditPermission,
        (p) => `/events/${p.data?.event?.id}/edit`
      ),
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const itemColDefs: ColDef<Item>[] = itemGenerateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };

  const handlePriceChange = (value: number) => {
    setFlatFee(value);
  };
  const handleClone = () => {
    navigate(`/vendors/${vendor.id}/clone`);
  };
  return (
    <>
      <Form method="post" action={`/vendors/${vendor.id}/edit`}>
        {deletePermission && (
          <div className="flex">
            <div className="ml-auto py-2">
              <TertiaryButton onClick={handleClone}>Clone Vendor</TertiaryButton>
              <DeleteModal
                onConfirm={() => {
                  fetcher.submit(
                    {},
                    {
                      method: "delete",
                      action: `/vendors/${vendor.id}/delete`,
                    }
                  );
                }}
              >
                {({ setOpen }) => (
                  <SecondaryButton onClick={() => setOpen(true)}>
                    Delete
                  </SecondaryButton>
                )}
              </DeleteModal>
            </div>
          </div>
        )}
        <FormLayout>
          <div className="col-span-6">
            <Input
              label="Name"
              name="name"
              defaultValue={vendor.name}
              required
            />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
              name="is_active"
              label="Is Active"
              defaultChecked={vendor.is_active}
            />
          </div>
          {permissions === "admin" && (
            <div className="col-span-6">
              <CustomSelect
                title="Organization"
                name="organization_id"
                required
                defaultValue={vendor.organization_id}
                options={organizations.map((org) => ({
                  label: org.name,
                  value: org.id,
                }))}
              />
            </div>
          )}
          {permissions !== "admin" && (
            <input
              type="hidden"
              value={vendor.organization_id ?? ""}
              name="organization_id"
            />
          )}
          <div className="col-span-6">
            <CustomSelect
              title="Vendor Type"
              name="type"
              required
              defaultValue={type}
              onChange={(v) => setType(v)}
              options={[
                ...(vendorType?.map((v) => ({
                  label: v.reference_label,
                  value: v.reference_value,
                })) || []),
              ]}
            />
          </div>
          {type === VendorType.OTHER && (
            <div className="col-span-6">
              <Input
                label="Other Type"
                name="other_type"
                required
                defaultValue={vendor.other_type}
              />
            </div>
          )}
          <div className="col-span-6">
            <Input
              label="Point-of-Contact Name"
              name="poc_name"
              defaultValue={vendor.poc_name}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Point-of-Contact E-mail"
              name="poc_email"
              defaultValue={vendor.poc_email}
            />
          </div>
          <div className="col-span-6">
            <Input
              label="Point-of-Contact Phone Number"
              name="poc_phone"
              defaultValue={vendor.poc_phone}
            />
          </div>
          <div className="col-span-4">
            <PercentageInput
              label="Revenue Share"
              name="revenue_share"
              defaultValue={((vendor?.revenue_share || 0) * 100).toFixed(2)}
            />
          </div>
          <div className="col-span-2">
            <IndeterminateCheckbox
              name="sales_tax_responsible"
              label="Sales Tax Responsible"
              defaultChecked={vendor?.sales_tax_responsible || false}
            />
          </div>
          <div className="col-span-6">
            <PriceInput
              value={flatFee}
              required
              label="Flat Fee"
              name="flat_fee"
              onChange={(value) => handlePriceChange(value ? value : 0)}
            />
          </div>
        </FormLayout>
      </Form>
      <div className=" w-full pt-4 ">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-300 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-gray-400 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/75">
                <span>Location</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className=" pb-2 text-sm bg-gray-300 text-gray-500  pt-1">
                <div style={{ height: "75vh" }} className="bg-gray-300 p-1  ">
                  <div
                    className=" w-full rounded-2xl ag-theme-quartz bg-gray-300 p-4  "
                    style={{ width: "100%", height: "100%" }}
                  >
                    <AgGridReact
                      rowData={location}
                      columnDefs={locationColDefs}
                      rowHeight={rowHeight}
                      autoSizeStrategy={autoSizeStrategy}
                      pagination={true}
                      gridOptions={gridOptions}
                      paginationPageSize={100}
                      onGridReady={(params) => (gridRef.current = params.api)}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className=" w-full pt-4 ">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-300 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-gray-400 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/75">
                <span>Menus</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className=" pb-2 text-sm bg-gray-300 text-gray-500  pt-1">
                <div style={{ height: "75vh" }} className="bg-gray-300 p-1  ">
                  <div
                    className=" w-full rounded-2xl ag-theme-quartz bg-gray-300 p-4  "
                    style={{ width: "100%", height: "100%" }}
                  >
                    <AgGridReact
                      rowData={menus}
                      columnDefs={menuColDefs}
                      rowHeight={rowHeight}
                      autoSizeStrategy={autoSizeStrategy}
                      pagination={true}
                      gridOptions={gridOptions}
                      paginationPageSize={100}
                      onGridReady={(params) => (gridRef.current = params.api)}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className=" w-full pt-4 ">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-300 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-gray-400 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/75">
                <span>Items</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className=" pb-2 text-sm text-gray-500 bg-gray-300 pt-1">
                <div style={{ height: "75vh" }} className="bg-gray-300 p-1  ">
                  <div
                    className=" w-full rounded-2xl ag-theme-quartz bg-gray-300 p-4  "
                    style={{ width: "100%", height: "100%" }}
                  >
                    <AgGridReact
                      rowData={items}
                      columnDefs={itemColDefs}
                      rowHeight={rowHeight}
                      autoSizeStrategy={autoSizeStrategy}
                      pagination={true}
                      gridOptions={gridOptions}
                      paginationPageSize={100}
                      onGridReady={(params) => (gridRef.current = params.api)}
                    />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};
