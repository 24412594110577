import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { ShowRow } from "../../components/ShowRow";
import { ShowLayout } from "../../layout/ShowLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Transaction } from "../../types/transaction";
import { capitalize, snakeCaseToSentenceCase } from "../../utils";
import {GET_TICKETING_TRANSACTION, GET_TRANSACTION} from "./queries";

const client = createApolloClient();

export const transactionShowLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const { data } = await client.query({
    query: GET_TRANSACTION,
    variables: {
      id,
    },
  });
  return data.transactions_by_pk;
};

export const TransactionShow = () => {
  const transaction = useLoaderData() as Transaction;
  return (
    <ShowLayout header={`Reference ${transaction.payment.reference_id}`}>
      <ShowRow label="Order ID" value={transaction.order_id} />
      <ShowRow label="Status" value={capitalize(transaction.payment.status)} />
      <ShowRow label="Payment ID" value={transaction.payment_id} />
      <ShowRow
        label="Payment Type"
        value={snakeCaseToSentenceCase(transaction.payment.payment_type)}
      />
      <ShowRow label="Amount" value={transaction.payment.amount} />
      <ShowRow
        label="Payment Data"
        value={
          transaction.payment.payment_data &&
          Object.keys(transaction.payment.payment_data).length > 0 &&
          JSON.stringify(transaction.payment.payment_data, null, 2)
        }
      />
    </ShowLayout>
  );
};

export const transactionTicketingShowLoader = async ({ params }: LoaderFunctionArgs) => {
    const { id } = params;
    const { data } = await client.query({
        query: GET_TICKETING_TRANSACTION,
        variables: {
            id,
        },
    });
    return data.transactions_ticketing_by_pk;
};

export const TransactionTicketingShow = () => {
    const transaction = useLoaderData() as Transaction;
    return (
        <ShowLayout header={`Reference ${transaction.payment.reference_id}`}>
            <ShowRow label="Order ID" value={transaction.order_id} />
            <ShowRow label="Status" value={capitalize(transaction.payment.status)} />
            <ShowRow label="Payment ID" value={transaction.payment_id} />
            <ShowRow
                label="Payment Type"
                value={snakeCaseToSentenceCase(transaction.payment.payment_type)}
            />
            <ShowRow label="Amount" value={transaction.payment.amount} />
            <ShowRow
                label="Payment Data"
                value={
                    transaction.payment.payment_data &&
                    Object.keys(transaction.payment.payment_data).length > 0 &&
                    JSON.stringify(transaction.payment.payment_data, null, 2)
                }
            />
        </ShowLayout>
    );
};