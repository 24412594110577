import { RFIDAssetsRow } from "./type";
import { Link } from "react-router-dom";
import { Badge } from "../../../components/Badge";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import React, {useState} from "react";
import PromoCodeSendModal from "../../../components/PromoCodeSendModal";
import toast from "react-hot-toast";
import {createApolloClient} from "../../../providers/ApolloClientFactory";
import {TICKET_SEND_EMAIL_SMS} from "../ticket-search/mutations";


const client = createApolloClient();
const ButtonCellRenderer = (props: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [type, setType] = useState('');
    const { data } = props;
    const { id, uid } = data;

    const handleButtonClick = (sendType: string) => {
        setType(sendType);
        setIsModalOpen(true);
    };

    return (
        <>
            <PromoCodeSendModal
                isOpen={isModalOpen}
                title={"Send Ticket via "}
                onClose={() => setIsModalOpen(false)}
                type={type}
                onConfirm={async ({
                                      type,
                                      value,
                                      setOpen,
                                      setEmail,
                                      setPhoneNumber,
                                      setType,
                                  }) => {
                    try {
                        await client.mutate({
                            mutation: TICKET_SEND_EMAIL_SMS,
                            variables: {
                                data: {
                                    uid: uid,
                                    methodType: 'qr_code',
                                    email: type === "mail" ? value : undefined,
                                    phone_number: type !== "mail" ? value : undefined,
                                    id: `${id}`,
                                    type,
                                },
                            },
                        });

                        toast.success("Ticket QR Code Sent Successfully");
                        setOpen(false);
                        setEmail("");
                        setPhoneNumber("");
                        setType("");
                    } catch (error) {
                        toast.error("Error Sending Ticket QR Code: " + error);
                    }
                }}
            />
            <button
                onClick={() => handleButtonClick("mail")}
                className="rounded-md border border-gray-300 bg-primary-600 py-1 px-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
                Send Email
            </button>
            <button
                onClick={() => handleButtonClick("sms")} // Set type to sms and open modal
                className="ml-2 rounded-md border border-gray-300 bg-primary-600 py-1 px-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
                Send SMS
            </button>
        </>
    );
};


type CellRenderer = (params: { value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; data: RFIDAssetsRow; }) => JSX.Element;

const createCellRenderer = (permission: boolean, linkFunc: (params: { data: RFIDAssetsRow }) => string): CellRenderer => {
  return (params) => (
      <>
        {permission ? (
            <Link to={linkFunc(params)}>
              <Badge>{params.value}</Badge>
            </Link>
        ) : (
            <Badge>{params.value}</Badge>
        )}
      </>
  );
};

const generateColDefs = (): ColDef<RFIDAssetsRow>[] => [
  {
    headerName: 'UID',
    valueGetter: p => p.data?.uid,
    cellRenderer: createCellRenderer(
        true,
        (p) => `/rfid-assets/${p.data.id}/edit`
    ),
  },
  {
    headerName: 'Type',
    valueGetter: p => p.data?.is_qr,
    valueFormatter: (p) => {
      return p.data?.is_active ? "QR" : "RFID";
    },
  },
  {
    headerName: "Token Balance",
    valueGetter: (params) => {
      const tokensBalance = params?.data?.tokens_balance;
      console.log("tokensBalance:", tokensBalance); // Log the tokensBalance object
      if (tokensBalance && Object.keys(tokensBalance).length > 0) {
        const tokenStrings = Object.values<any>(tokensBalance).map(
            (token) => `${token.redeemable_token_name} - ${token.balance}`
        );
        return tokenStrings.join(", ");
      }
      return null;
    },
    cellRenderer: (params: ICellRendererParams) => {
      const tokensBalance = params.value;

      if (tokensBalance) {
        return <div>{tokensBalance}</div>;
      }
      return null;
    },
  },
  {
    headerName: "Is Active?",
    valueGetter: (p) => p.data?.is_active,
    valueFormatter: (p) => {
      return p.data?.is_active ? "Yes" : "No";
    },
  },
  {
    headerName: "Actions",
    cellRenderer: ButtonCellRenderer, // Use the button cell renderer
  }
];

export const rfidColDefs: ColDef<RFIDAssetsRow>[] = generateColDefs();