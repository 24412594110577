import toast from "react-hot-toast";
import { CREATE_PROMO_CODE } from "./mutations";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Input } from "../../components/inputs/Input";
import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
} from "react-router-dom";
import { FormLayout } from "../../layout/FormLayout";
import RadioGroup from "../../components/inputs/RadioGroup";
import PercentageInput from "../../components/inputs/PercentageInput";
import PriceInput from "../../components/inputs/PriceInput";
import { useEffect, useState } from "react";
import CustomSelect from "../../components/customSelect";
import { customAlphabet } from "nanoid";
import { alphabet } from "../../utils";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { useEventStore } from "../../store/event";
import {
  GET_PROMO_CODE_ITEMS,
  GET_PROMO_CODE_ITEM_CATEGORIES,
  GET_PROMO_CODE_PK,
} from "./queries";
import { Categories, Item } from "../../types/item";
import {
  appliesToOptions,
  promoCodeTypes,
  promoMethods,
  triggerRulesOptions,
} from "../../utils";
import { userStore } from "../../store/user";
import { PromoCode } from "../../types/promoCode";

const client = createApolloClient();
const { getState: getOrganizationStore } = organizationStore;
const { getState: getUserStore } = userStore;

export const promoCodeCloneLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getOrganizationStore().organizationId;

  const { id } = params;
  const { data } = await client.query({
    query: GET_PROMO_CODE_PK,
    variables: { id },
  });

  const { data: itemCategoriesData } = await client.query({
    query: GET_PROMO_CODE_ITEM_CATEGORIES,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_category",
            },
          }
        : {
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_category",
            },
          },
    },
  });

  const { data: itemData } = await client.query({
    query: GET_PROMO_CODE_ITEMS,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _in: [organizationId],
            },
            is_active: {
              _eq: true,
            },
          }
        : {
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_category",
            },
          },
    },
  });

  return {
    data: data.promo_code_by_pk,
    itemCategories: itemCategoriesData.dashboard_reference,
    items: itemData.items,
  };
};

export const promoCodeCloneAction = async ({ request }: ActionFunctionArgs) => {
  const user = getUserStore().user;

  const data = await request.formData();
  const inclusiveCategory = data.getAll("inclusive_category");
  const inclusiveItems = data.getAll("inclusive_items");
  const excludedCategory = data.getAll("excluded_category");
  let excludedItems = data.getAll("excluded_items");
  excludedItems = excludedItems?.filter((item) => item);

  const formData = Object.fromEntries(data);
  const quantity = parseInt(`${formData?.quantity || 1}`);
  const methodType = formData?.method_type;
  const promoCodeValueType = formData?.promo_code_value_type;
  const triggerRule = formData?.trigger_rule;
  const spendAmount = formData?.spend_amount;
  const spendQuantity = formData?.spend_quantity;
  const evaluateAgainst = formData?.evaluate_against;
  const discountAppliesTo = formData?.discount_applies_to;
  for (let index = 0; index < quantity; index++) {
    const element = index;
    const nanoIdGenerate =
      methodType === "qr_code"
        ? customAlphabet(alphabet, 12)
        : customAlphabet(alphabet, 8);
    const uid = nanoIdGenerate();
    const percentage = (formData.percentage || "0") as string;
    const percentagePer = (parseFloat(percentage) / 100).toFixed(2);

    const promo_payload = {
      promo_code_value_type: promoCodeValueType,
      trigger: triggerRule
        ? {
            rule: triggerRule,
            spend_amount:
              triggerRule === "spend_threshold" ? +spendAmount * 100 : null,
            spend_quantity:
              triggerRule !== "spend_threshold" ? spendQuantity : null,
            evaluate_against: evaluateAgainst,
            discount_applies_to:
              promoCodeValueType !== "amount_off" ? discountAppliesTo : null,
          }
        : null,
      percentage: promoCodeValueType !== "amount_off" ? percentagePer : null,
      amount:
        promoCodeValueType === "amount_off" ? +formData?.amount * 100 : null,
      inclusive_items: inclusiveItems,
      inclusive_category: inclusiveCategory,
      excluded_category: excludedCategory,
      excluded_items: excludedItems,
    };
    const createData = {
      organization_id: formData.organization_id,
      event_id: formData.event_id,
      attendee_id: null,
      promo_payload,
      uid,
      promo_code_type: methodType,
      description: formData.description,
      last_updated_by: user?.id,
    };
    setTimeout(() => {}, 1000);
    try {
      await client.mutate({
        mutation: CREATE_PROMO_CODE,
        variables: { data: createData },
      });
      toast.success(`${index + 1}/${quantity} Promo code Cloned.`);
    } catch (error) {
      console.log({ error });
      return toast.error(`${index + 1} Failed To Clone Promo Code`);
    }
  }
  return redirect("/promocode/list");
};

export const PromoCodeClone = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizations = useOrganizationStore((state) => state.organizations);
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const eventId = useEventStore((state) => state.eventId);
  const { fetchAllEvents, allEvents: events } = useEventStore((state) => state);

  const { data, itemCategories, items } = useLoaderData() as {
    data: PromoCode;
    itemCategories: Categories[];
    items: Item[];
  };
  const [amount, setAmount] = useState(
    (data?.promo_payload?.amount ?? 0) / 100
  );
  const [spendAmount, setSpendAmount] = useState(
    (data?.promo_payload?.trigger?.spend_amount ?? 0) / 100
  );
  const [type, setType] = useState(
    data?.promo_payload?.promo_code_value_type || promoCodeTypes?.[0].value
  );
  const [triggerRule, setTriggerRule] = useState(
    data?.promo_payload?.trigger?.rule || triggerRulesOptions?.[0]?.value
  );

  const [appliesTo, setAppliesTo] = useState(appliesToOptions?.[0]?.value);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(
    data?.event?.id || eventId
  );

  const [inclusive, setInclusive] = useState<{
    category: string[];
    item: number[];
  }>({
    category:
      data?.promo_payload?.inclusive_category?.filter((data) => data) || [],
    item: data?.promo_payload?.inclusive_items || [],
  });

  const [exclusive, setExclusive] = useState<{
    category: string[];
    item: number[] | string[];
  }>({
    category: [],
    item: [],
  });
  const [inclusiveOptions, setInclusiveOptions] = useState<{
    category: string[];
    item: number[];
  }>({
    category: [],
    item: [],
  });

  const [exclusiveOptions, setExclusiveOptions] = useState<{
    category: string[];
    item: number[];
  }>({
    category: [],
    item: [],
  });

  useEffect(() => {
    if (data?.id) {
      setSelectedEventId(data?.event?.id || eventId);
      setType(
        data?.promo_payload?.promo_code_value_type || promoCodeTypes?.[0].value
      );
      setTriggerRule(
        data?.promo_payload?.trigger?.rule || triggerRulesOptions?.[0]?.value
      );
      setAppliesTo(
        data?.promo_payload?.trigger?.applies_to || appliesToOptions?.[0]?.value
      );
      setInclusive({
        category:
          data?.promo_payload?.inclusive_category?.filter((data) => data) || [],
        item:
          data?.promo_payload?.inclusive_items?.filter((data) => data) || [],
      });
      setExclusive({
        category:
          data?.promo_payload?.excluded_category?.filter((data) => data) || [],
        item: data?.promo_payload?.excluded_items?.filter((data) => data) || [],
      });
    }
  }, [data]);

  useEffect(() => {
    fetchAllEvents();
  }, []);

  useEffect(() => {
    if (selectedEventId) {
      const filteredItems = items.filter(
        (item) => item.event_id === selectedEventId
      );
      setInclusiveOptions((prevState) => ({
        ...prevState,
        item:
          prevState.category.length > 0
            ? filteredItems
                .filter((item) => inclusive.category.includes(item.category))
                .map((item) => item.id)
            : filteredItems.map((item) => item.id),
      }));
    }
  }, [selectedEventId, inclusive.category, items]);

  useEffect(() => {
    if (selectedEventId) {
      const filteredItems = items.filter(
        (item) => item.event_id === selectedEventId
      );
      setExclusiveOptions((prevState) => ({
        ...prevState,
        item:
          prevState.category.length > 0
            ? filteredItems
                .filter(
                  (item: Item) =>
                    !inclusive.item.includes(item.id) &&
                    inclusive.category.includes(item.category)
                )
                .map((item) => item.id)
            : filteredItems
                .filter((item) => !inclusive.item.includes(item.id))
                .map((item) => item.id),
      }));
    }
  }, [selectedEventId, inclusive.item, inclusive.category, items]);

  useEffect(() => {
    setExclusive((prevState) => ({
      ...prevState,
      category: itemCategories
        .filter(
          (category) => !inclusive.category.includes(category.reference_value)
        )
        .map((category) => category.reference_value),
    }));
  }, [inclusive.category, itemCategories]);
  const handleAmountChange = (value: number) => {
    setAmount(value);
  };
  const handleSpendAmountChange = (value: number) => {
    setSpendAmount(value);
  };
  const clonedPromoCodeDes = `${data.description} - clone`;
  return (
    <Form method="post" action={`/promocode/${data.id}/clone`}>
      <FormLayout>
        <div className="flex justify-between">
          <p className="block text-lg font-medium text-gray-700">
            UID: Cloning
          </p>
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={data.organization.id}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={organizationId ?? ""}
            name="organization_id"
          />
        )}
        <div className="col-span-6">
          <CustomSelect
            title="Event"
            name="event_id"
            required
            defaultValue={data.event.id || eventId}
            options={events.map((event) => ({
              label: event.name,
              value: event.id,
            }))}
            onChange={(value) => {
              const eventId = parseInt(value);
              setSelectedEventId(eventId);
            }}
          />
        </div>
        <div className="col-span-6">
          <Input
            defaultValue={clonedPromoCodeDes}
            label="Description"
            placeholder="Description"
            type="text"
            required
            name="description"
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Entry Method"
            name="method_type"
            required
            defaultValue={data.promo_code_type}
            options={promoMethods.map((method) => ({
              label: method.label,
              value: method.value,
            }))}
          />
        </div>
        <div className="col-span-6 bg-gray-100 p-2 rounded">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-1">
              <RadioGroup
                label="Promo Code Type"
                defaultChecked={type}
                name="promo_code_value_type"
                onChange={(value) => setType(value)}
                choices={promoCodeTypes.map((promoCodeType) => ({
                  title: promoCodeType.label,
                  id: promoCodeType.value,
                }))}
              />
            </div>
            <div className="col-span-5">
              <div className="w-full" role="group" aria-labelledby="">
                {type === "percentage_off" ? (
                  <PercentageInput
                    defaultValue={(
                      (data?.promo_payload?.percentage || 1) * 100
                    ).toFixed(2)}
                    label="Percentage"
                    name="percentage"
                  />
                ) : (
                  <PriceInput
                    value={amount}
                    required
                    label="Amount"
                    name="amount"
                    onChange={(value) => handleAmountChange(value ? value : 0)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 bg-gray-100 p-2 rounded">
          <div className="grid grid-cols-8 gap-10">
            <div className="col-span-2">
              <RadioGroup
                name="trigger_rule"
                label="Trigger Rule"
                defaultChecked={triggerRule || ""}
                onChange={(value) => setTriggerRule(value)}
                choices={triggerRulesOptions.map((triggerRule) => ({
                  title: triggerRule.label,
                  id: triggerRule.value || "",
                }))}
              />
            </div>
            {triggerRule ? (
              <>
                <div className="col-span-2">
                  <RadioGroup
                    label="Evaluate Against"
                    name="evaluate_against"
                    defaultChecked={
                      data?.promo_payload?.trigger?.evaluate_against ||
                      appliesTo
                    }
                    onChange={(value) => setAppliesTo(value)}
                    choices={appliesToOptions.map((appliesTo) => ({
                      title: appliesTo.label,
                      id: appliesTo.value,
                    }))}
                  />
                </div>
                <div className="col-span-2">
                  {triggerRule === "spend_threshold" ? (
                    <PriceInput
                      required
                      label="Required Spend Amount"
                      name="spend_amount"
                      value={spendAmount}
                      onChange={(value) =>
                        handleSpendAmountChange(value ? value : 0)
                      }
                    />
                  ) : (
                    <Input
                      required
                      label="Required Quantity"
                      placeholder="Quantity"
                      type="number"
                      name="spend_quantity"
                      defaultValue={
                        data?.promo_payload?.trigger?.spend_quantity
                      }
                    />
                  )}
                </div>
                {type === "percentage_off" ? (
                  <div className="col-span-2">
                    <RadioGroup
                      label="Discount Applies To"
                      name="discount_applies_to"
                      defaultChecked={
                        data?.promo_payload?.trigger?.discount_applies_to
                      }
                      onChange={(value) => setAppliesTo(value)}
                      choices={appliesToOptions.map((appliesTo) => ({
                        title: appliesTo.label,
                        id: appliesTo.value,
                      }))}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-span-6 bg-gray-100 p-2 rounded">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-3">
              <CustomSelect
                isDisabled={
                  inclusive?.item ? inclusive.item?.length > 0 : false
                }
                title="Inclusive Category (OR)"
                name="inclusive_category"
                multi
                defaultValue={data?.promo_payload?.inclusive_category}
                options={[
                  ...(itemCategories.map((category) => ({
                    label: category.reference_label,
                    value: category.reference_value,
                  })) || []),
                ]}
                onChange={(category) =>
                  setInclusive((prevState) => ({
                    ...prevState,
                    category: category as string[],
                  }))
                }
              />
              {triggerRule &&
              appliesTo === "inclusive_only" &&
              (inclusive?.category || inclusive?.item)?.length <= 0 ? (
                <p className="text-red-500">
                  Choose Inclusive Category or Item{" "}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="col-span-3">
              <CustomSelect
                isDisabled={
                  inclusive?.category ? inclusive?.category?.length > 0 : false
                }
                defaultValue={data?.promo_payload?.inclusive_items}
                title="Inclusive Items (OR)"
                name="inclusive_items"
                multi
                options={items
                  .filter((item) => item.event_id === selectedEventId)
                  .map((item) => ({
                    label: item.short_name || "",
                    value: item.id,
                  }))}
                onChange={(item) =>
                  setInclusive((prevState) => ({
                    ...prevState,
                    item: item as number[],
                  }))
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-6 mt-2 gap-4">
            <div className="col-span-3">
              <CustomSelect
                defaultValue={data?.promo_payload?.excluded_category}
                title="Excluded Category"
                name="excluded_category"
                multi
                options={[
                  ...(itemCategories
                    .filter(
                      (category) =>
                        !inclusiveOptions.category.includes(
                          category.reference_value
                        )
                    )
                    .map((category) => ({
                      label: category.reference_label,
                      value: category.reference_value,
                    })) || []),
                ]}
                onChange={(category) =>
                  setExclusive((prevState) => ({
                    ...prevState,
                    category: category as string[],
                  }))
                }
              />
            </div>
            <div className="col-span-3">
              <CustomSelect
                title="Excluded Items"
                name="excluded_items"
                multi
                defaultValue={data?.promo_payload?.excluded_items}
                options={items
                  .filter(
                    (item) =>
                      item.event_id === selectedEventId &&
                      !inclusive.item.includes(item.id) &&
                      (inclusive.category.length === 0 ||
                        inclusive.category.includes(item.category))
                  )
                  .map((item) => ({
                    label: item.short_name || "",
                    value: item.id,
                  }))}
                onChange={(item) =>
                  setExclusive((prevState) => ({
                    ...prevState,
                    item: item as number[],
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="col-span-6 mt-4">
          <Input
            required
            label="Quantity"
            placeholder="Quantity"
            type="number"
            name="quantity"
          />
        </div>
      </FormLayout>
    </Form>
  );
};
