import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import toast from "react-hot-toast";
import ReportHeader from "../../../reports/components/ReportHeader";
import Loader from "../../../../components/Loader";
import {
  formatData,
  hourColumns,
} from "../../../reports/point-of-sale/financial-hourly-report/helpers";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../../store/organization";
import { userStore } from "../../../../store/user";
import { REPORTS_FINANCIAL_HOURLY } from "../../../reports/point-of-sale/financial-cumulative-hourly-report/fragment";
import { FinancialHourlyReportRow } from "../../../reports/point-of-sale/financial-cumulative-hourly-report/type";
import FuelGauge from "../charts/GuageWithNeedle";
import moment from "moment";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

const usdFormatter = (value: any) => {
  if (typeof value !== "number" || isNaN(value)) {
    value = 0; // Default to 0 for invalid or missing data
  }
  return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};

const LineChartComponent = ({ rows }: { rows: FinancialHourlyReportRow[] }) => {
  if (!rows || rows.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full pb-4">
        <div className="text-gray-500 text-sm">No data to display</div>
      </div>
    );
  }
  const [day1, day2, day3] = rows;

  const day1ChartData = hourColumns.map((column) => {
    const totalNetSales =
      (day1 as Record<string, any>)?.[
        (
          column as {
            accessorKey: keyof FinancialHourlyReportRow;
          }
        ).accessorKey
      ] ?? 0; // Default to 0 if the value is undefined
    const date = day1?.display_date ?? "No Date"; // Default to "No Date" if missing
    return {
      hour: column.header,
      totalNetSales,
      date,
    };
  });

  const day2ChartData = hourColumns.map((column) => {
    const totalNetSales =
      (day2 as Record<string, any>)?.[
        (
          column as {
            accessorKey: keyof FinancialHourlyReportRow;
          }
        ).accessorKey
      ] ?? 0; // Default to 0 if the value is undefined
    const date = day2?.display_date ?? "No Date"; // Default to "No Date" if missing
    return {
      hour: column.header,
      totalNetSales,
      date,
    };
  });

  const day3ChartData = hourColumns.map((column) => {
    const totalNetSales =
      (day3 as Record<string, any>)?.[
        (
          column as {
            accessorKey: keyof FinancialHourlyReportRow;
          }
        ).accessorKey
      ] ?? 0; // Default to 0 if the value is undefined
    const date = day3?.display_date ?? "No Date"; // Default to "No Date" if missing
    return {
      hour: column.header,
      totalNetSales,
      date,
    };
  });

  const mergedChartData = day1ChartData.map((entry, index) => ({
    hour: entry.hour,
    totalNetSalesRecord1: entry.totalNetSales,
    totalNetSalesRecord2: day2ChartData[index].totalNetSales || [],
    totalNetSalesRecord3: day3ChartData[index].totalNetSales || [],
    date: entry.date,
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={480}
        height={300}
        data={mergedChartData}
        margin={{
          top: 30,
          right: 10,
          left: 0,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="hour"
          tick={{
            fontSize: "0.875rem",
            fontWeight: 600,
            fill: "#4a5568",
          }}
          interval={8}
          angle={0}
        />
        <YAxis
          width={100}
          tickFormatter={usdFormatter}
          tick={{
            fontSize: "0.875rem",
            fontWeight: 600,
            fill: "#4a5568",
          }}
          // interval={2}
        />
        <Tooltip
          itemSorter={(item) => {
            return (item.value as number) * -1;
          }}
          contentStyle={{
            fontSize: "0.875rem",
            fontWeight: 600,
            backgroundColor: "#fff",
            border: "1px solid #e2e8f0",
            color: "#4a5568",
          }}
          formatter={usdFormatter}
        />
        <Legend />
        {day1?.display_date && (
          <Line
            type="monotone"
            dataKey="totalNetSalesRecord1"
            name={`${day1.display_date}`}
            stroke="#B71C1C"
            strokeWidth={2}
          />
        )}
        {day2?.display_date && (
          <Line
            type="monotone"
            dataKey="totalNetSalesRecord2"
            name={`${day2.display_date}`}
            stroke="black"
            strokeWidth={2}
          />
        )}
        {day3?.display_date && (
          <Line
            type="monotone"
            dataKey="totalNetSalesRecord3"
            name={`${day3.display_date}`}
            stroke="#1C1CB7"
            strokeWidth={2}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

const SalesCard = () => {
  const organizationId = getState().organizationId;
  const user = getUserStore().user;
  const [financialData, setFinancialData] = useState<
    FinancialHourlyReportRow[]
  >([]);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let financialWhere: any = {
          organization_id: { _eq: organizationId },
        };
        if (user?.events) {
          financialWhere = {
            ...financialWhere,
            event_id: { _in: user?.events },
          };
        }
        if (user?.vendors) {
          financialWhere = {
            ...financialWhere,
            vendor_id: { _in: user?.vendors },
          };
        }

        const financialDataResponse = await client.query({
          query: REPORTS_FINANCIAL_HOURLY,
          variables: { where: financialWhere },
        });

        setRowData(financialDataResponse?.data?.reports_financial_hourly || []);
        setFinancialData(
          financialDataResponse?.data?.reports_financial_hourly || []
        );
      } catch (error) {
        console.error("Error fetching financial data:", error);
        toast.error("Error fetching financial data.");
      } finally {
        setIsLoading(false);
      }
    };
    if (organizationId || user) fetchData();
  }, [organizationId]);

  const formattedData = formatData(rowData);
  const chartRows = formattedData.filter((row) => row.display_date !== "Total");
  return (
    <div className="w-full  grid grid-cols-1 md:grid-cols-2 gap-3 p-5 bg-white shadow">
      <div className="w-full rounded-lg grid grid-cols-1 md:grid-cols-1 bg-gray-200 shadow">
        <div className="justify-items-center">
          <ReportHeader
            title="Net Hourly Sales"
            toolTipShow={true}
            tooltipText={
              "This report shows daily net sales, broken down hour-by-hour from 5am to 5am the next day. Each row is a distinct day, with columns representing net sales for each hour. It offers insight into hourly sales trends and daily sales momentum. Perfect for tracking and comparing sales performance throughout the day."
            }
          />
        </div>
        <div className="bg-white rounded-md p-1 mx-3 m-2">
          {isLoading ? (
            <div className="w-full grid justify-items-center content-center">
              <Loader />
            </div>
          ) : (
            <ResponsiveContainer
              className="pb-2 bg-white rounded-md mx-3 grid justify-items-center"
              width="98%"
              height="97%"
            >
              <LineChartComponent rows={chartRows} />
            </ResponsiveContainer>
          )}
        </div>
      </div>

      <div className="w-full rounded-lg bg-gray-200 shadow">
        <div className="justify-items-center pb-3">
          <ReportHeader
            title={`Average Net Per Hour for ${moment(chartRows[0]?.id)
              .utc()
              .startOf("day")
              .format("MM-DD-YYYY")}`}
            toolTipShow={true}
            tooltipText={
              "This gauge shows the Average Net Hourly Sales for the last day of activity, using hour-by-hour from 5am to 5am the next day. The lower left number is the Minimum Sales hour with the lower right being the Maximum Sales Hour."
            }
          />
        </div>
        <div className="bg-white rounded-md p-1 pb-14 ml-3 mr-3 mb-2">
          {isLoading ? (
            <div className="w-full grid justify-items-center content-center">
              <Loader />
            </div>
          ) : (
            <FuelGauge rows={chartRows} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
