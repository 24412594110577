import {
  redirect,
  ActionFunctionArgs,
  Form,
  useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { useOrganizationStore } from "../../store/organization";
import { CreatePaymentProcessor } from "./mutations";
import { useState } from "react";
import PercentageInput from "../../components/inputs/PercentageInput";
import { userStore } from "../../store/user";
import { organizationStore } from "../../store/organization";
import { PAYMENT_PROCESSOR_CATAGORIES } from "./queries";
import { Categories } from "../../types/item";

const client = createApolloClient();
const { getState: getUserStore } = userStore;
const { getState: getOrganizationStore } = organizationStore;
export const paymentProcessorsLoader = async () => {
  const organizationId = getOrganizationStore().organizationId;
  const { data: paymentTypeData } = await client.query({
    query: PAYMENT_PROCESSOR_CATAGORIES,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "payment_processors_processor",
            },
          }
        : {},
    },
  });
  return {
    paymentType: paymentTypeData.dashboard_reference,
  };
};

export const paymentProcessorCreateAction = async ({
  request,
}: ActionFunctionArgs) => {
  const user = getUserStore().user;

  const formData = await request.formData();
  const body = Object.fromEntries(formData.entries());
  try {
    const processingRate = (body.processing_rate || "0") as string;
    const processingRatePer = (parseFloat(processingRate) / 100).toFixed(8);
    await client.mutate({
      mutation: CreatePaymentProcessor,
      variables: {
        input: {
          payment_processor: body.payment_processor,
          processor: body.processor,
          organization_id: body.organization_id || null,
          config: {
            secretKey: body.secretKey,
            merchantId: body.merchantId,
            consumerKey: body.consumerKey,
            merchantAccountId: body.merchantAccountId || null,
          },
          is_active: body.is_active === "true",
          processing_rate: processingRatePer,
          last_updated_by: user?.id,
        },
      },
    });
    toast.success("Payment processor created");
    return redirect("/payment-processors/list", 302);
  } catch {
    toast.error("Unable to create payment processor");
    return null;
  }
};

export const PaymentProcessorCreate = () => {
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const organizations = useOrganizationStore((state) => state.organizations);
  const [processor, setProcessor] = useState("");
  const { paymentType } = useLoaderData() as {
    paymentType: Categories[];
  };

  return (
    <Form method="post" action="/payment-processors/create">
      <FormLayout>
        <div className="col-span-4">
          <CustomSelect
            title="Organization"
            name="organization_id"
            required
            options={[
              ...organizations.map((org) => ({
                value: org.id,
                label: org.name,
              })),
            ]}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={true}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Processor"
            required
            name="processor"
            onChange={(value) => setProcessor(value as string)}
            options={[
              ...(paymentType?.map((v) => ({
                label: v.reference_label,
                value: v.reference_value,
              })) || []),
            ]}
          />
        </div>
        {processor === "mxmerchant" && (
          <>
            <div className="col-span-6">
              <Input
                label="Payment Processor Name"
                name="payment_processor"
                required
              />
            </div>
            <div className="col-span-6">
              <Input label="Consumer Key" name="consumerKey" required />
            </div>
            <div className="col-span-6">
              <Input label="Secret Key" name="secretKey" required />
            </div>
            <div className="col-span-6">
              <Input label="Merchant ID" name="merchantId" required />
            </div>
          </>
        )}
        {processor === "braintree" && (
          <>
            <div className="col-span-6">
              <Input
                label="Payment Processor Name"
                name="payment_processor"
                required
              />
            </div>
            <div className="col-span-6">
              <Input label="Public Key" name="consumerKey" required />
            </div>
            <div className="col-span-6">
              <Input label="Private Key" name="secretKey" required />
            </div>
            <div className="col-span-6">
              <Input label="Merchant ID" name="merchantId" required />
            </div>
            <div className="col-span-6">
              <Input
                label="Merchant Account ID"
                name="merchantAccountId"
                required
              />
            </div>
          </>
        )}
        {processor ? (
          <div className="col-span-6">
            <PercentageInput
              defaultValue={0}
              label="Processing Rate"
              name="processing_rate"
            />
          </div>
        ) : (
          ""
        )}
      </FormLayout>
    </Form>
  );
};
