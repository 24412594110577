import {
  LoaderFunctionArgs,
  ActionFunctionArgs,
  Form,
  useLoaderData,
  redirect,
  useFetcher,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
  LOCATIONS_BY_ORGANIZATION,
  QUERY_LIST_ITEMS,
} from "../../queries";
import { organizationStore } from "../../store/organization";
import { Item } from "../../types/item";
import { Location } from "../../types/location";
import { Menu } from "../../types/menu";
import { Vendor } from "../../types/vendor";
import {
  CREATE_LOCATION_MENUS,
  CREATE_MENU_ITEMS,
  DELETE_LOCATION_MENUS,
  DELETE_MENU_ITEMS,
  UPDATE_MENU,
} from "./mutations";
import { GET_MENU } from "./queries";
import { usePermissions } from "../auth/api";
import { userStore } from "../../store/user";
import { useEffect, useState } from "react";
import { usdFormat } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import DeleteModal from "../../components/DeleteModal";
import {SecondaryButton, TertiaryButton} from "../../components/Button";
import { Event } from "../../types/event";

type LocationsIds = string;
type ItemsIds = string;
const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getUserState } = userStore;

export const menuEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const organizationId = getState().organizationId;

  const user = getUserState().user;

  let vendorWhere = {};
  let locationWhere: any = {};
  let queryWhere: any = {};
  let eventWhere = {};

  locationWhere = {
    ...locationWhere,
    is_active: { _eq: true },
  };

  queryWhere = {
    ...queryWhere,
    is_active: { _eq: true },
  };
  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }
  if (user?.events) {
    eventWhere = {
      ...eventWhere,
      id: {
        _in: user?.events,
      },
    };
  }

  if (user?.events) {
    locationWhere = {
      ...locationWhere,
      event: {
        id: {
          _in: user?.events,
        },
      },
    };
    queryWhere = {
      ...queryWhere,
      event: {
        id: {
          _in: user?.events,
        },
      },
    };
  }

  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    eventWhere = {
      ...eventWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    locationWhere = {
      ...locationWhere,
      event: {
        ...(locationWhere?.event || {}),
        organization_id: {
          _eq: organizationId,
        },
      },
    };

    if (user?.vendors) {
      locationWhere = {
        ...locationWhere,
        vendor_id: {
          _in: user?.vendors,
        },
      };
    }

    queryWhere = {
      ...queryWhere,
      event: {
        ...(queryWhere?.event || {}),
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }

  const [
    { data: menuData },
    { data: itemData },
    { data: vendorData },
    { data: locationData },
    { data: eventsData },
  ] = await Promise.all([
    client.query({
      query: GET_MENU,
      variables: {
        id,
      },
    }),
    client.query({
      query: QUERY_LIST_ITEMS,
      variables: {
        where: queryWhere,
        order_by: {
          name: "asc",
        },
      },
    }),
    client.query({
      query: GET_LIST_VENDORS_BY_ORGANIZATION,
      variables: {
        where: vendorWhere,
      },
    }),
    client.query({
      query: LOCATIONS_BY_ORGANIZATION,
      variables: {
        where: locationWhere,
      },
    }),
    client.query({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where: eventWhere,
      },
    }),
  ]);

  return {
    menu: menuData.menus_by_pk,
    vendors: vendorData.vendors,
    items: itemData.items,
    locations: locationData.locations,
    events: eventsData.events,
  };
};
export const menuEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const permissions = getUserState().permissions;
  const user = getUserState().user;

  const { id } = params;
  const body = await request.formData();

  const locationsIds: LocationsIds[] = body.getAll(
    "locationsIds"
  ) as LocationsIds[];
  const itemsIds: ItemsIds[] = body.getAll("itemsIds") as ItemsIds[];

  body.delete("itemsIds");
  body.delete("locationsIds");

  const arrayFromValue = Array.from(
    { length: 6 },
    (_, valueIndex) => valueIndex + 1
  );
  const keys: Array<any> = [];
  arrayFromValue.forEach((value: number) => {
    keys.push(`cat_${value}`, `cat_${value}_hide`, `cat_${value}_items`);
  });

  const allItemsValue = body.get("all_items");
  let allItemsIds: string[] = [];
  if (typeof allItemsValue === "string") {
    allItemsIds = JSON.parse(allItemsValue);
  }
  const allItemsIdsStringArray = allItemsIds.map((id) => id.toString());
  body.delete("all_items");
  const bodyData: any = Object.fromEntries(body);
  const category = {
    cat_1: bodyData?.cat_1 || "",
    cat_1_hide: bodyData?.cat_1_hide === "true",
    cat_1_itemsIds: bodyData?.cat_1_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_2: bodyData?.cat_2,
    cat_2_hide: bodyData?.cat_2_hide === "true",
    cat_2_itemsIds: bodyData.cat_2_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_3: bodyData?.cat_3,
    cat_3_hide: bodyData?.cat_3_hide === "true",
    cat_3_itemsIds: bodyData.cat_3_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_4: bodyData?.cat_4,
    cat_4_hide: bodyData?.cat_4_hide === "true",
    cat_4_itemsIds: bodyData.cat_4_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_5: bodyData?.cat_5,
    cat_5_hide: bodyData?.cat_5_hide === "true",
    cat_5_itemsIds: bodyData.cat_5_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
    cat_6: bodyData?.cat_6,
    cat_6_hide: bodyData?.cat_6_hide === "true",
    cat_6_itemsIds: bodyData.cat_6_items
      ?.split(",")
      ?.filter((item: string) => item && allItemsIdsStringArray.includes(item)),
  };
  keys.forEach((key) => delete bodyData[key]);

  try {
    await Promise.all([
      client.mutate({
        mutation: DELETE_LOCATION_MENUS,
        variables: { id },
      }),
      client.mutate({
        mutation: DELETE_MENU_ITEMS,
        variables: { id },
      }),
    ]);

    let mutationPromises = [];

    if (!(locationsIds.length === 1 && locationsIds[0].trim() === "")) {
      mutationPromises.push(
        client.mutate({
          mutation: CREATE_LOCATION_MENUS,
          variables: {
            objects: locationsIds.map((locationId) => ({
              location_id: locationId,
              menu_id: id,
            })),
          },
        })
      );
    }

    if (!(itemsIds.length === 1 && itemsIds[0].trim() === "")) {
      mutationPromises.push(
        client.mutate({
          mutation: CREATE_MENU_ITEMS,
          variables: {
            objects: itemsIds.map((itemId) => ({
              item_id: itemId,
              menu_id: id,
            })),
          },
        })
      );
    }

    // Always update the menu
    mutationPromises.push(
      client.mutate({
        mutation: UPDATE_MENU,
        variables: {
          id,
          input: { ...bodyData, category, last_updated_by: user?.id },
        },
      })
    );

    await Promise.all(mutationPromises);

    toast.success("Menu updated successfully");
    return redirect("/menus/list");
  } catch (error) {
    toast.error("Error updating menu: " + error);
  }
};

export const MenuEdit = () => {
  const { permissions } = usePermissions();
  const {
    menu,
    items: allItems,
    vendors,
    locations,
    events,
  } = useLoaderData() as {
    menu: Menu;
    items: Item[];
    vendors: Vendor[];
    locations: Location[];
    events: Event[];
  };
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState<string>(
    `${menu.event_id}`
  );
  const [selectedVendor, setSelectedVendor] = useState<string>(
    `${menu.vendor_id}`
  );

  const organizationId = getState().organizationId;
  const [filteredLocations, setFilteredLocations] =
    useState<Location[]>(locations);
  const {
    data: itemsData,
    isLoading: itemsLoading,
    isFetching: itemsIsFetching,
    error: itemsError,
  } = useQuery<{
    itemsData: Item[];
  }>(
    [
      "table-data-menu-item-edi-download",
      organizationId,
      selectedEvent,
      selectedVendor,
    ],
    async () => {
      let where: any = {};
      if (selectedEvent && selectedVendor) {
        if (organizationId) {
          where = {
            ...where,
            is_active: { _eq: true },
            event: {
              organization_id: { _eq: organizationId },
            },
          };
        }
        where = {
          ...where,
          is_active: { _eq: true },
          event_id: { _eq: selectedEvent },
          vendor_id: { _eq: selectedVendor },
        };

        const { data, errors } = await client.query({
          query: QUERY_LIST_ITEMS,
          variables: {
            where,
            order_by: {
              name: "asc",
            },
          },
        });
        return {
          itemsData: data.items,
        };
      }
      return {
        itemsData: [],
      };
    }
  );
  const items = itemsData?.itemsData || [];
  const {
    data: itemsAllVendorsData,
    isLoading: itemsAllVendorsLoading,
    isFetching: itemsAllVendorsIsFetching,
    error: itemsAllVendorsError,
  } = useQuery<{
    itemsAllVendorsData: Item[];
  }>(
    [
      "table-data-menu-item-download-all-vendors",
      organizationId,
      selectedEvent,
    ],
    async () => {
      let where: any = {};

      if (selectedEvent) {
        if (organizationId) {
          where = {
            ...where,
            is_active: { _eq: true },
            event: {
              organization_id: { _eq: organizationId },
            },
          };
        }
        where = {
          ...where,
          is_active: { _eq: true },
          event_id: { _eq: selectedEvent },
          vendor_id: { _eq: "0" }, // Use "0" as the vendor_id
        };

        const { data, errors } = await client.query({
          query: QUERY_LIST_ITEMS,
          variables: {
            where,
            order_by: {
              name: "asc",
            },
          },
        });
        return {
          itemsAllVendorsData: data.items,
        };
      }
      return {
        itemsAllVendorsData: [],
      };
    }
  );
  const itemsAllVendors = itemsAllVendorsData?.itemsAllVendorsData || [];
  const itemIds = items
    .map((item) => item.id)
    .concat(itemsAllVendors.map((item) => item.id));

  const [categoryData, setCategory] = useState<any>({
    cat_1: "",
    cat_1_hide: false,
    cat_1_items: menu?.category?.cat_1_itemsIds || [],
    cat_1_itemsData: items,
    cat_2: "",
    cat_2_hide: false,
    cat_2_items: menu?.category?.cat_2_itemsIds || [],
    cat_2_itemsData: items,
    cat_3: "",
    cat_3_hide: false,
    cat_3_items: menu?.category?.cat_3_itemsIds || [],
    cat_3_itemsData: items,
    cat_4: "",
    cat_4_hide: false,
    cat_4_items: menu?.category?.cat_4_itemsIds || [],
    cat_4_itemsData: items,
    cat_5: "",
    cat_5_hide: false,
    cat_5_items: menu?.category?.cat_5_itemsIds || [],
    cat_5_itemsData: items,
    cat_6: "",
    cat_6_hide: false,
    cat_6_items: menu?.category?.cat_6_itemsIds || [],
    cat_6_itemsData: items,
  });

  const onSelectCategoryItem = (
    e: number[],
    item_category: string,
    index: number,
    categoryDatas: any = categoryData
  ) => {
    let oldItems: any = [...e];
    const arrayGenerateStart = Array.from(
      { length: index - 1 },
      (value, valueIndex) => valueIndex
    );

    const arrayGenerate =
      index !== 6
        ? Array.from(
            { length: 8 - (index + 1) },
            (_, valueIndex) => index + valueIndex
          )
        : [];
    arrayGenerateStart.forEach((generateStart: number) => {
      const itemsData = categoryDatas[`cat_${generateStart + 1}_items`];

      oldItems = [...oldItems, ...itemsData];
    });
    oldItems = [...new Set(oldItems?.filter((data?: number) => data))];

    const newItems = items?.filter(
      (item: Item) => !oldItems?.includes(item.id)
    );

    let newItemObj: any = {};
    // For the items generate
    arrayGenerate.forEach((generateIndex: number) => {
      newItemObj[`cat_${generateIndex + 1}_itemsData`] = newItems;

      newItemObj[`cat_${generateIndex + 1}_items`] = [];
    });
    const newCategory = {
      ...categoryDatas,
      ...newItemObj,
      [item_category]: e,
    };
    setCategory({
      ...newCategory,
    });
    return newCategory;
  };
  const [tips, setTips] = useState<boolean>(
    menu?.is_tips === null || menu?.is_tips === undefined ? true : menu.is_tips
  );
  const [discounts, setDiscounts] = useState<boolean>(
    menu?.is_discount === null || menu?.is_discount === undefined
      ? true
      : menu?.is_discount
  );
  const [isDiscountProtected, setIsDiscountProtected] = useState(
    menu?.is_discount_protected || false
  );
  const [printing, setPrinting] = useState(menu?.is_printing || false);
  const [isPrintingAuto, setIsPrintingAuto] = useState(
    menu?.is_printing_auto || false
  );
  const [isPrintingCopies, setIsPrintingCopies] = useState<number>(
    menu?.is_printing_copies
  );
  const onCheckUnCheckItem = (
    id: number,
    checked: any,
    item_category: string,
    index: number
  ) => {
    const checkedValues = categoryData[item_category];
    const newItemCategory: number[] = [...(checkedValues || [])];
    if (checked) {
      newItemCategory.push(id);
    } else {
      const itemIndex = newItemCategory.indexOf(id);
      newItemCategory.splice(itemIndex, 1);
    }
    setCategory({
      ...categoryData,
      [item_category]: newItemCategory,
    });
    // onSelectCategoryItem(newItemCategory, item_category, index);
  };

  useEffect(() => {
    if (menu?.category) {
      const generateArray = Array.from(
        { length: 6 },
        (_, valueIndex) => valueIndex + 1
      );

      let newCategory: any = {
        ...categoryData,
      };
      generateArray.forEach((item: number) => {
        const key: any = `cat_${item}_itemsIds`;

        const categoryItem =
          menu?.category?.[key]?.map((item: string) => parseInt(item)) || [];

        const newCategoryData = onSelectCategoryItem(
          categoryItem,
          `cat_${item}_items`,
          item,
          newCategory
        );
        newCategory = {
          ...newCategoryData,
        };
      });

      setCategory({
        ...newCategory,
      });
    }

    return () => {};
  }, [menu]);
  useEffect(() => {
    if (selectedEvent) {
      const updatedLocations = locations.filter(
        (location) => location.event_id.toString() === selectedEvent.toString()
      );
      setFilteredLocations(updatedLocations);
    } else {
      setFilteredLocations([]);
    }
  }, [selectedEvent, locations]);
  const { deletePermission } = usePermissions("menus");
  const fetcher = useFetcher();
  const handleClone = () => {
    navigate(`/menus/${menu.id}/clone`);
  };
  return (
    <Form method="put" action={`/menus/${menu.id}/edit`}>
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <TertiaryButton onClick={handleClone}>Clone Menu</TertiaryButton>
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/menus/${menu.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
      <FormLayout isTopSave={true}>
        <div className="col-span-5 sm:col-span-3">
          <Input label="Name" name="name" defaultValue={menu.name} />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={menu.is_active}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Event"
            name="event_id"
            defaultValue={menu.event_id}
            required
            options={[
              ...(events?.map((e) => ({
                label: e.name,
                value: e.id,
              })) || []),
            ]}
            onChange={(e) => setSelectedEvent(e)}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Vendor"
            name="vendor_id"
            defaultValue={menu.vendor_id}
            options={vendors.map((vendor) => ({
              label: vendor.name,
              value: vendor.id,
            }))}
            onChange={(e) => setSelectedVendor(e)}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Locations"
            name="locationsIds"
            defaultValue={menu?.location_menus?.map(
              (location) => location.location_id
            )}
            options={filteredLocations.map((location) => ({
              label: location.name,
              value: location.id,
            }))}
            multi
          />
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Accepted Payment Methods
          </label>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="Cash?"
              name="is_cash"
              defaultChecked={menu?.is_cash}
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="Credit?"
              name="is_credit"
              defaultChecked={menu?.is_credit}
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="RFID?"
              name="is_rfid"
              defaultChecked={menu?.is_rfid}
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="QR?"
              name="is_qr"
              defaultChecked={menu?.is_qr}
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="Tabs?"
              name="is_tab"
              defaultChecked={menu?.is_tab}
            />
          </div>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="Tappit?"
              name="is_tappit"
              defaultChecked={menu?.is_tappit}
            />
          </div>
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Tip Configuration
          </label>
          <div className="col-span-6 sm:col-span-1">
            <IndeterminateCheckbox
              label="Tips Accepted?"
              name="is_tips"
              defaultChecked={menu?.is_tips}
              onChange={(checked) => setTips(checked as boolean)}
            />
          </div>
          {tips && (
            <div className="col-span-2 sm:col-span-1">
              <Input
                label="Tip Percentage 1"
                name="tip_percentage_1"
                defaultValue={menu?.tip_percentage_1}
                required
              />
            </div>
          )}
          {tips && (
            <div className="col-span-2 sm:col-span-1">
              <Input
                label="Tip Percentage 2"
                name="tip_percentage_2"
                defaultValue={menu?.tip_percentage_2}
                required
              />
            </div>
          )}
          {tips && (
            <div className="col-span-2 sm:col-span-1">
              <Input
                label="Tip Percentage 3"
                name="tip_percentage_3"
                defaultValue={menu?.tip_percentage_3}
                required
              />
            </div>
          )}
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Feature Configuration
          </label>
          <div className="col-span-3 sm:col-span-1">
            <IndeterminateCheckbox
              label="Custom Items Available?"
              name="is_custom_item"
              defaultChecked={menu?.is_custom_item}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <IndeterminateCheckbox
              label="No Tax on Cash?"
              name="is_cash_not_taxed"
              defaultChecked={menu?.is_cash_not_taxed}
            />
          </div>
          <div
            className={discounts ? "col-span-3 sm:col-span-1" : "col-span-1"}
          >
            <IndeterminateCheckbox
              label="Discounts Available?"
              name="is_discount"
              defaultChecked={menu?.is_discount}
              onChange={(checked) => {
                setDiscounts(checked as boolean);
                if (!checked) setIsDiscountProtected(false);
              }}
            />
          </div>
          {discounts && (
            <div className="col-span-3 sm:col-span-1">
              <IndeterminateCheckbox
                label="Discounts Password Protected?"
                name="is_discount_protected"
                defaultChecked={isDiscountProtected}
                onChange={(checked) =>
                  setIsDiscountProtected(checked as boolean)
                }
                required
              />
            </div>
          )}
          <div className="col-span-2 sm:col-span-1">
            <IndeterminateCheckbox
              label="Scan to Cart Mode?"
              name="is_cart_scanning"
              defaultChecked={menu?.is_cart_scanning}
            />
          </div>
          <div className={printing ? "col-span-3 sm:col-span-1" : "col-span-6"}>
            <IndeterminateCheckbox
              label="Ticket Printing Enabled?"
              name="is_printing"
              defaultChecked={menu?.is_printing}
              onChange={(checked) => {
                setPrinting(checked as boolean);
                if (!checked) setIsPrintingAuto(false);
                if (!checked) setIsPrintingCopies(0);
              }}
            />
          </div>
          {printing && (
            <div
              className={
                isPrintingAuto ? "col-span-3 sm:col-span-1" : "col-span-3"
              }
            >
              <IndeterminateCheckbox
                label="Auto Print Tickets on Order Completion?"
                name="is_printing_auto"
                defaultChecked={menu?.is_printing_auto}
                onChange={(checked) => {
                  setIsPrintingAuto(checked as boolean);
                  if (!checked) setIsPrintingCopies(0);
                }}
              />
            </div>
          )}
          {isPrintingAuto && (
            <div className="col-span-6 sm:col-span-4">
              <Input
                label="Ticket Copies to Automatically Print?"
                name="is_printing_copies"
                defaultValue={menu?.is_printing_copies}
              />
            </div>
          )}
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Show Tax Line on Order Completion"
            name="tax_type"
            defaultValue={menu.tax_type}
            options={[
              { label: "No", value: "inclusive" },
              { label: "Yes", value: "exclusive" },
            ]}
          />
        </div>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 1 "
            name="cat_1"
            maxLength={15}
            defaultValue={categoryData?.cat_1_hide ? "" : menu?.category?.cat_1}
            // updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_1_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_1_hide: !!data,
              });
            }}
            defaultChecked={menu?.category?.cat_1_hide}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_1_items"
                    defaultChecked={categoryData.cat_1_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_1_items", 1)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_1_items"
                      defaultChecked={categoryData.cat_1_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_1_items", 1)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 2 "
            name="cat_2"
            maxLength={15}
            defaultValue={categoryData?.cat_2_hide ? "" : menu?.category?.cat_2}
            // updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_2_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_2_hide: !!data,
              });
            }}
            defaultChecked={menu?.category?.cat_2_hide}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_2_items"
                    defaultChecked={categoryData.cat_2_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_2_items", 2)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_2_items"
                      defaultChecked={categoryData.cat_2_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_2_items", 2)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 3 "
            name="cat_3"
            maxLength={15}
            defaultValue={categoryData?.cat_3_hide ? "" : menu?.category?.cat_3}
            // updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_3_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_3_hide: !!data,
              });
            }}
            defaultChecked={menu?.category?.cat_3_hide}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_3_items"
                    defaultChecked={categoryData.cat_3_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_3_items", 3)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_3_items"
                      defaultChecked={categoryData.cat_3_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_3_items", 3)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 4 "
            name="cat_4"
            maxLength={15}
            defaultValue={categoryData?.cat_4_hide ? "" : menu?.category?.cat_4}
            // updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_4_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_4_hide: !!data,
              });
            }}
            defaultChecked={menu?.category?.cat_4_hide}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_4_items"
                    defaultChecked={categoryData.cat_4_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_4_items", 4)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_4_items"
                      defaultChecked={categoryData.cat_4_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_4_items", 4)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 5 "
            name="cat_5"
            maxLength={15}
            defaultValue={categoryData?.cat_5_hide ? "" : menu?.category?.cat_5}
            // updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_5_hide"
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_5_hide: !!data,
              });
            }}
            defaultChecked={menu?.category?.cat_5_hide}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_5_items"
                    defaultChecked={categoryData.cat_5_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_5_items", 5)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_5_items"
                      defaultChecked={categoryData.cat_5_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_5_items", 5)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <div className="col-span-5 sm:col-span-3">
          <Input
            label="Category 6 "
            name="cat_6"
            maxLength={15}
            defaultValue={categoryData?.cat_6_hide ? "" : menu?.category?.cat_6}
            // updateValue={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Hide?"
            name="cat_6_hide"
            defaultChecked={menu?.category?.cat_6_hide}
            onChange={(data: any) => {
              setCategory({
                ...categoryData,
                cat_6_hide: !!data,
              });
            }}
          />
        </div>
        <>
          {selectedEvent && selectedVendor && (
            <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              {items.map((item: Item) => (
                <div className="col-span-2 sm:col-span-1 ">
                  <IndeterminateCheckbox
                    label={`${item.name} (${usdFormat.format(
                      item.price / 100
                    )})`}
                    name="cat_6_items"
                    defaultChecked={categoryData.cat_6_items?.includes(
                      item?.id
                    )}
                    onChange={(e) =>
                      onCheckUnCheckItem(item.id, e, "cat_6_items", 6)
                    }
                  />
                </div>
              ))}
              <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-300 p-2 rounded">
                <label className="col-span-6 block text-sm font-medium text-gray-700">
                  Event Items
                </label>
                {itemsAllVendors.map((item: Item) => (
                  <div className="col-span-2 sm:col-span-1 ">
                    <IndeterminateCheckbox
                      label={`${item.name} (${usdFormat.format(
                        item.price / 100
                      )})`}
                      name="cat_6_items"
                      defaultChecked={categoryData.cat_6_items?.includes(
                        item?.id
                      )}
                      onChange={(e) =>
                        onCheckUnCheckItem(item.id, e, "cat_6_items", 6)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
        <input
          type="hidden"
          value={categoryData.cat_1_items}
          name="cat_1_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_2_items}
          name="cat_2_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_3_items}
          name="cat_3_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_4_items}
          name="cat_4_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_5_items}
          name="cat_5_items"
        />
        <input
          type="hidden"
          value={categoryData.cat_6_items}
          name="cat_6_items"
        />
        <input type="hidden" value={JSON.stringify(itemIds)} name="all_items" />
        {!isPrintingAuto && (
          <input type="hidden" value={0} name="is_printing_copies" />
        )}
      </FormLayout>
    </Form>
  );
};
