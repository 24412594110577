import { useNavigate, useNavigation } from "react-router-dom";
import { createApolloClient } from "../providers/ApolloClientFactory";
import { PrimaryButton } from "./Button";

const client = createApolloClient();

export function FormFooter() {
  const navigation = useNavigation();
  const navigate = useNavigate();

  const handleClick = async () => {
    await client.clearStore();
  };

  return (
    <div className="pt-5">
      <div className="flex justify-end">
        <div className="grid gap-2 grid-cols-2">
          <button
            onClick={() => navigate(-1)}
            type="button"
            style={{ lineHeight: 2 }}
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <PrimaryButton
            type="submit"
            isLoading={navigation.state === "loading" || navigation.state === "submitting"}
            onClick={handleClick}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
