import { gql } from "@apollo/client";
export const UPDATE_POS_GLOSSARY = gql`
  mutation UpdateDashboardRefByID(
    $id: Int!
    $reference_value: String!
  ) {
    update_dashboard_reference_by_pk(
      pk_columns: { id: $id }
      _set: {
        reference_value: $reference_value
      }
    ) {
      id
    }
  }
`;