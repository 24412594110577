import React, { useEffect, useState } from "react";
import ReportHeader from "../../../reports/components/ReportHeader";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../../../store/user";
import { organizationStore } from "../../../../store/organization";
import { Queries_mutations } from "../../blogPost/queries_mutations";
import Loader from "../../../../components/Loader";
import { PrimaryButton, SecondaryButton } from "../../../../components/Button";
import { Blog } from "../../type";

const client = createApolloClient();
const { getState: getUserStore } = userStore;
const { getState: getOrganizationStore } = organizationStore;

export const blogCreateLoader = async () => {
  const organizationId = getOrganizationStore().organizationId;
  const { data } = await client.query({
    query: Queries_mutations,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _eq: organizationId,
            },
          }
        : {},

      reference_type: "dashboard_blog_entry",
    },
  });

  const { data: posData } = await client.query({
    query: Queries_mutations,
    variables: {
      where: organizationId
        ? {
            organization_id: {
              _eq: organizationId,
            },
          }
        : {},

      reference_type: "posapp_blog_entry",
    },
  });

  return {
    blogs: data.dashboard_reference,
    posBlogs: posData.dashboard_reference,
  };
};

const WhatsNewCard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [blogData, setBlogData] = useState<Blog[]>([]);
  const [posBlogData, setPosBlogData] = useState<Blog[]>([]);
  const user = getUserStore().user;
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { blogs = [], posBlogs = [] } = await blogCreateLoader();
        const formattedBlogs = blogs.map((blog: any) => ({
          ...blog,
          updated_at: formatDate(blog.updated_at),
        }));

        const formattedPosBlogs = posBlogs.map((posBlog: any) => ({
          ...posBlog,
          updated_at: formatDate(posBlog.updated_at),
        }));
        setBlogData(formattedBlogs);
        setPosBlogData(formattedPosBlogs);
      } catch (error) {
        console.error("Failed to fetch blogs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="grid w-full overflow-hidden rounded-b-lg bg-white shadow p-5 gap-y-10 py-4">
      <div className="w-full overflow-hidden rounded-lg bg-gray-200 shadow">
        <div className="w-full overflow-hidden rounded-lg shadow grid grid-cols-1 sm:grid-cols-2 gap-3 p-5">
          <div className="w-full overflow-hidden rounded-lg bg-white m-0 p-5 shadow">
            <div className="grid justify-items-center pt-5 px-10 py-4">
              <ReportHeader title="Dashboard News" />
            </div>
            {user?.role?.name === "admin" && (
              <div className="pr-4 justify-self-end">
                <PrimaryButton onClick={() => navigate(`/blogpost/create`)}>
                  Create
                </PrimaryButton>
              </div>
            )}
            <div className="flex flex-col w-full items-center">
              {isLoading ? (
                <div className="bg-gray-200 shadow-xl flex rounded-lg w-full md:w-80 my-4 px-5 py-3">
                  <Loader />
                </div>
              ) : (
                <ul className="list-none">
                  {blogData.length > 0 ? (
                    blogData.map((blog) => (
                      <div className="bg-gray-200 shadow-xl flex flex-col rounded-lg w-06 min-h-[200px] my-9 p-6 mx-4">
                        <li className="items-center py-3" key={blog.id}>
                          <div className="flex flex-col w-full space-y-2">
                            <span className="text-lg font-bold text-gray-800">
                              {blog.reference_label}
                            </span>
                            <div
                              className="prose max-w-none custom-quill text-gray-700"
                              dangerouslySetInnerHTML={{
                                __html: blog.reference_value,
                              }}
                            ></div>

                            <span className="text-base font-medium text-gray-700">
                              {blog.reference_links}
                            </span>
                            {/* <span className="text-base font-medium text-gray-700">
                              {blog.reference_images}
                            </span> */}
                          </div>

                          <div className="flex justify-between items-center w-full mt-4">
                            <span className="leading-loose text-gray-500">
                              {blog.updated_at}
                            </span>
                            {user?.role?.name === "admin" && (
                              <Link to={`/blogpost/${blog.id}/edit`}>
                                <SecondaryButton>Edit</SecondaryButton>
                              </Link>
                            )}
                          </div>
                        </li>
                      </div>
                    ))
                  ) : (
                    <div className="bg-gray-200 shadow-xl flex rounded-lg w-80 min-h-[250px] my-4 p-6 mx-4">
                      <div className="flex flex-col w-full space-y-2">
                        <span className="text-lg font-bold text-gray-800">
                          Well, This Is Awkward..
                        </span>
                        <span className="text-base font-medium text-gray-700">
                          No blog entries available.
                        </span>
                      </div>
                    </div>
                  )}
                </ul>
              )}
            </div>
          </div>

          <div className="w-full overflow-hidden rounded-lg bg-white m-0 p-5 shadow">
            <div className="grid justify-items-center pt-5 px-10 py-4">
              <ReportHeader title="POS App News" />
            </div>
            {user?.role?.name === "admin" && (
              <div className="pr-4 justify-self-end">
                <PrimaryButton onClick={() => navigate(`/blogpost-pos/create`)}>
                  Create
                </PrimaryButton>
              </div>
            )}
            <div className="flex flex-col w-full items-center">
              {isLoading ? (
                <div className="bg-gray-200 shadow-xl flex rounded-lg w-full md:w-80 my-4 px-5 py-3">
                  <Loader />
                </div>
              ) : (
                <ul className="list-none">
                  {posBlogData.length > 0 ? (
                    posBlogData.map((blog) => (
                      <div className="bg-gray-200 shadow-xl flex flex-col rounded-lg w-06 min-h-[200px] my-9 p-6 mx-4">
                        <li className="items-center py-3" key={blog.id}>
                          <div className="flex flex-col w-full space-y-2">
                            <span className="text-lg font-bold text-gray-800">
                              {blog.reference_label}
                            </span>
                            <div
                                className="prose max-w-none custom-quill text-gray-700"
                                dangerouslySetInnerHTML={{
                                  __html: blog.reference_value,
                                }}
                            ></div>
                          </div>

                          <div className="flex justify-between items-center w-full mt-4">
                            <span className="leading-loose text-gray-500">
                              {blog.updated_at}
                            </span>
                            {user?.role?.name === "admin" && (
                              <Link to={`/blogpost-pos/${blog.id}/edit`}>
                                <SecondaryButton>Edit</SecondaryButton>
                              </Link>
                            )}
                          </div>
                        </li>
                      </div>
                    ))
                  ) : (
                    <div className="bg-gray-200 shadow-xl flex rounded-lg w-80 min-h-[250px] my-4 p-6 mx-4">
                      <div className="flex flex-col w-full space-y-2">
                        <span className="text-lg font-bold text-gray-800">
                          Well, This Is Awkward..
                        </span>
                        <span className="text-base font-medium text-gray-700">
                          No blog entries available.
                        </span>
                      </div>
                    </div>
                  )}
                </ul>
              )}
            </div>

            <div className="flex justify-end px-10 align-bottom"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNewCard;
