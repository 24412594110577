import {
  useLoaderData,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  Form,
  redirect,
  useFetcher,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { UPDATE_ORGANIZATION } from "./mutations";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Input } from "../../components/inputs/Input";
import { FormLayout } from "../../layout/FormLayout";
import { Organization } from "../../types/organization";
import { GET_ORGANIZATION } from "./queries";
import { organizationStore } from "../../store/organization";
import DeleteModal from "../../components/DeleteModal";
import {SecondaryButton, TertiaryButton} from "../../components/Button";
import { usePermissions } from "../auth/api";
import { userStore } from "../../store/user";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

export const organizationEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const fetchOrganizations = getState().fetchOrganizations;
  const { id } = params;
  const data = await request.formData();
  const user = getUserStore().user;

  const inputData = {
    name: data.get("name"),
    is_active: data.get("is_active"),
    logo_url: data.get("logo_url"),
    background_url: data.get("background_url"),
    business_name: data.get("business_name"),
    business_address_1: data.get("business_address_1"),
    business_address_2: data.get("business_address_2"),
    business_city: data.get("business_city"),
    business_state: data.get("business_state"),
    business_zipcode: data.get("business_zipcode"),
    business_website: data.get("business_website"),
    business_phone_number: data.get("business_phone_number"),
  };

  try {
    await client.mutate({
      mutation: UPDATE_ORGANIZATION,
      variables: {
        id,
        name: inputData.name,
        is_active: inputData.is_active,
        user_id: user?.id,
        logo_url: inputData.logo_url,
        background_url: inputData.background_url,
        business_name: inputData.business_name,
        business_address_1: inputData.business_address_1,
        business_address_2: inputData.business_address_2,
        business_city: inputData.business_city,
        business_state: inputData.business_state,
        business_zipcode: inputData.business_zipcode,
        business_website: inputData.business_website,
        business_phone_number: inputData.business_phone_number,
      },
    });
    toast.success("Organization updated successfully");
    await fetchOrganizations();
    return redirect("/organizations/list");
  } catch {
    toast.error("Failed to edit organization");
  }
};

export const organizationEditLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const { id } = params;
  try {
    const { data } = await client.query({
      query: GET_ORGANIZATION,
      variables: { id },
    });
    return data.organizations_by_pk;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    throw new Error("Could not fetch organization data");
  }
};

export const OrganizationsEdit = () => {
  const navigate = useNavigate();
  const organization = useLoaderData() as Organization;
  const { deletePermission } = usePermissions("organizations");
  const fetcher = useFetcher();
  const handleClone = () => {
    navigate(`/organizations/${organization.id}/clone`);
  };
  return (
    <Form action={`/organizations/${organization.id}/edit`} method="put">
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <TertiaryButton onClick={handleClone}>
              Clone Organization
            </TertiaryButton>
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/organizations/${organization.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
      <FormLayout>
        <div className="col-span-3 sm:col-span-3">
          <Input
            label="Name"
            type="text"
            name="name"
            required
            defaultValue={organization.name}
          />
        </div>
        <IndeterminateCheckbox
          label="Is Active?"
          name="is_active"
          defaultChecked={organization.is_active}
        />
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Invoicing Configuration
          </label>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Logo URL"
              name="logo_url"
              type="text"
              defaultValue={organization.logo_url}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Background URL"
              name="background_url"
              type="text"
              defaultValue={organization.background_url}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business Name"
              name="business_name"
              type="text"
              defaultValue={organization.business_name}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business Address 1"
              name="business_address_1"
              type="text"
              defaultValue={organization.business_address_1}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business Address 2"
              name="business_address_2"
              type="text"
              defaultValue={organization.business_address_2}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business City"
              name="business_city"
              type="text"
              defaultValue={organization.business_city}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business State"
              name="business_state"
              type="text"
              defaultValue={organization.business_state}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business Zipcode"
              name="business_zipcode"
              type="text"
              defaultValue={organization.business_zipcode}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business Website"
              name="business_website"
              type="text"
              defaultValue={organization.business_website}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Business Phone Number"
              name="business_phone_number"
              type="text"
              defaultValue={organization.business_phone_number}
            />
          </div>
        </div>
      </FormLayout>
    </Form>
  );
};
