import { useEffect, useState } from "react";
import {
  Form,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
  redirect,
  useFetcher,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import {
  UPDATE_ORGANIZATION_USER,
  UPDATE_ORGANIZATION_USER_NO_PASSWORD,
} from "./mutations";
import { GET_LIST_EVENTS_BY_ORGANIZATION, UserByPK } from "./queries";
import { usePermissions } from "../auth/api";
import { GET_LIST_VENDORS_BY_ORGANIZATION } from "../../queries";
import { Vendor } from "../../types/vendor";
import { OrganizationUser } from "./type";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { RolesListQuery } from "../roles/queries";
import { Roles } from "../roles/type";
import DeleteModal from "../../components/DeleteModal";
import {SecondaryButton, TertiaryButton} from "../../components/Button";
import { useUserStore, userStore } from "../../store/user";
type Event = {
  name: string;
  id: number;
};
const client = createApolloClient();
const { getState: getOriganizationState } = organizationStore;
const { getState: getUserStore } = userStore;

export const editUserAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const { id } = params;
  const user = getUserStore().user;

  const body = await request.formData();

  const eventIds = body.getAll("event_id");
  const vendorIds = body.getAll("vendor_id");
  const events = eventIds.length > 0 && eventIds[0] !== "" ? eventIds : null;
  const vendors =
    vendorIds.length > 0 && vendorIds[0] !== "" ? vendorIds : null;

  if (body.get("passwordUpdated") === "true") {
    try {
      await client.mutate({
        mutation: UPDATE_ORGANIZATION_USER,
        variables: {
          id,
          roleId: body.get("role_id"),
          password_hash: body.get("password"),
          username: body.get("username"),
          email: body.get("email") || null,
          first_name: body.get("first_name") || null,
          last_name: body.get("last_name") || null,
          phoneNumber: body.get("phone_number") || null,
          validationCode: body.get("validation_code"),
          vendor: vendors,
          tabletAccessCode: body.get("tablet_access_code") || null,
          orgId: body.get("organization_id"),
          events: events,
          is_active: body.get("is_active"),
          last_updated_by: user?.id,
        },
      });
      toast.success("User updated");
      return redirect(`/users/list`);
    } catch (error) {
      toast.error("Unable to update user: " + error);
    }
  } else {
    try {
      await client.mutate({
        mutation: UPDATE_ORGANIZATION_USER_NO_PASSWORD,
        variables: {
          id,
          roleId: body.get("role_id"),
          username: body.get("username"),
          email: body.get("email") || null,
          first_name: body.get("first_name") || null,
          last_name: body.get("last_name") || null,
          phoneNumber: body.get("phone_number") || null,
          validationCode: body.get("validation_code"),
          vendor: vendors,
          tabletAccessCode: body.get("tablet_access_code") || null,
          orgId: body.get("organization_id"),
          events: events,
          is_active: body.get("is_active"),
          last_updated_by: user?.id,
        },
      });
      toast.success("User updated");
      return redirect(`/users/list`);
    } catch (error) {
      toast.error("Unable to update user: " + error);
    }
  }
};

export const userByIdLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getOriganizationState().organizationId;
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const user = getUserStore().user;
  const admin = user?.role?.name === "admin"
  let vendorWhere = {};
  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }
  const { id } = params;
  const [{ data: usersData }, { data: vendors }, { data: roles }] =
    await Promise.all([
      client.query({
        query: UserByPK,
        variables: { id },
      }),
      client.query({
        query: GET_LIST_VENDORS_BY_ORGANIZATION,
        variables: {
          where: vendorWhere,
        },
      }),
      client.query({
        query: RolesListQuery,
        variables: {
          where: {
            is_active: { _eq: true },
            organization_id: organizationId
              ? { _in: [0, organizationId] }
              : { _in: [0] },
            ...(!admin && { id: { _nin: [1, 29] } }),
          },
        },
      }),
    ]);

  return {
    user: usersData?.users_by_pk,
    vendors: vendors?.vendors,
    roles: roles?.roles,
  };
};

const handleRedirect = () => {
  window.location.href = "/users/list";
};
export const UserEdit = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");

  const userData = useUserStore((state) => state.user);
  const { user, vendors, roles } = useLoaderData() as {
    user: OrganizationUser;
    vendors: Vendor[];
    roles: Roles[];
  };
  const [selectedRole, setSelectedRole] = useState<number | null>(user.role_id);
  const [passwordUpdate, setpasswordUpdate] = useState(false);
  const organizations = useOrganizationStore((state) => state.organizations);
  const organizationId = getOriganizationState().organizationId;
  const [eventOptions, setEventOptions] = useState([]);
  const [eventIds, setEventIds] = useState<number[] | null>([]);
  const [vendorsIds, setVendorsId] = useState<number[] | null>([]);

  const [selectedOrg, setSelectedOrg] = useState(user.organization_id);
  if (organizationId && user?.id && user?.organization_id != organizationId) {
    handleRedirect();
  }
  useEffect(() => {
    setEventIds(user?.events?.map((data) => +data) || []);
    setVendorsId(user?.vendors?.map((data) => +data) || []);
  }, [user?.events, user?.vendors]);
  useEffect(() => {
    const fetchEvents = async () => {
      if (selectedOrg) {
        try {
          let where: any = {
            organization_id: { _eq: selectedOrg },
          };
          if (userData?.events) {
            where = {
              ...where,
              id: {
                _in: userData?.events,
              },
            };
          }
          const { data } = await client.query({
            query: GET_LIST_EVENTS_BY_ORGANIZATION,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          });

          if (data && data.events) {
            const newEventOptions = data.events.map((event: Event) => ({
              label: event.name,
              value: event.id,
            }));
            setEventOptions(newEventOptions);
          }
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      } else {
        setEventOptions([]);
      }
    };
    fetchEvents();
  }, [selectedOrg]);
  const { deletePermission } = usePermissions("users");
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const handleClone = () => {
    navigate(`/users/${user.id}/clone`);
  };
  return (
    <Form method="post" action={`/users/${user.id}/edit`}>
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <TertiaryButton onClick={handleClone}>Clone User</TertiaryButton>
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/users/${user.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
      <FormLayout>
        <div className="col-span-4">
          <Input
            label="Username"
            name="username"
            required
            defaultValue={user.username}
          />
        </div>
        <div className="col-span-2">
          <IndeterminateCheckbox
            name="is_active"
            label="Is Active"
            defaultChecked={true}
          />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Update Password?"
            name="passwordUpdated"
            defaultChecked={false}
            onChange={(checked) => {
              setpasswordUpdate(checked as boolean);
            }}
          />
        </div>
        {passwordUpdate && (
          <div className="col-span-5">
            <Input label="Password" name="password" required />
          </div>
        )}
        <div className="col-span-6">
          <Input
            label="First Name"
            name="first_name"
            defaultValue={user.first_name}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Last Name"
            name="last_name"
            defaultValue={user.last_name}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Email"
            name="email"
            type="email"
            defaultValue={user.email}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Phone Number"
            name="phone_number"
            defaultValue={user.phone_number}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Tablet Access Code"
            name="tablet_access_code"
            required
            defaultValue={user.tablet_access_code || "22425"}
          />
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={user.organization_id}
              onChange={(v) => {
                setSelectedOrg(v);
                setEventOptions([]);
              }}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={user.organization_id ?? ""}
            name="organization_id"
          />
        )}

        <div className="col-span-6">
          <CustomSelect
            title="Role"
            name="role_id"
            required
            defaultValue={selectedRole}
            onChange={(v) => setSelectedRole(v)}
            options={[
              ...roles.map((role) => ({
                label: role.display_name,
                value: role.id,
              })),
            ]}
          />
        </div>
        {(eventOptions?.length || 0) > 0 ? (
          <div className="col-span-6">
            <CustomSelect
              title="Restrict to Event(s)"
              name="event_id"
              required
              multi
              defaultValue={eventIds}
              options={eventOptions || []}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="col-span-6">
          <CustomSelect
            title="Restrict to Vendor(s)"
            name="vendor_id"
            multi
            defaultValue={user?.vendors?.map((data) => +data) || []}
            options={[
              ...vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
            ]}
          />
        </div>
      </FormLayout>
    </Form>
  );
};
