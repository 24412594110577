import {
    ActionFunctionArgs,
    Form,
    LoaderFunctionArgs,
    redirect,
    useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../../components/inputs/IndeterminantCheckbox";
import {Input} from "../../../components/inputs/Input";
import PriceInput from "../../../components/inputs/PriceInput";
import {FormLayout} from "../../../layout/FormLayout";
import {createApolloClient} from "../../../providers/ApolloClientFactory";
import {Categories} from "../../../types/item";
import {GET_ITEM_CATEGORIES, GET_TICKETING_ITEM} from "./queries";
import {CREATE_TICKETING_ITEM} from "./mutations";
import {useEffect, useState} from "react";
import {userStore} from "../../../store/user";
import CustomSelect from "../../../components/customSelect";
import {
    GET_LIST_EVENTS_BY_ORGANIZATION,
} from "../../../queries";
import {organizationStore} from "../../../store/organization";
import {Event} from "../../../types/event";
import {PrimaryButton} from "../../../components/Button";
import {POSToken, TicketingItem} from "../../../types/tickets";
import TicketingItemHTML from "../components/TickingItemHTML";
// import {DatePicker} from "../../../components/DatePicker";
// import {parseAbsolute, parseDate, toZoned} from "@internationalized/date";

const client = createApolloClient();
const {getState} = userStore;
const {getState: getOrganizationStore} = organizationStore;

export const ticketingItemCloneLoader = async ({params}: LoaderFunctionArgs) => {
    const organizationId = getOrganizationStore().organizationId;
    const user = getState().user;

    let eventWhere = {};

    if (user?.events) {
        eventWhere = {
            ...eventWhere,
            id: {
                _in: user?.events,
            },
        };
    }

    if (organizationId) {
        eventWhere = {
            ...eventWhere,
            organization_id: {_eq: organizationId},
            is_active: {_eq: true},
        };
    }
    const {id} = params;
    const [
        {data: itemData},
        {data: eventData},
        {data: itemCategoriesData},
    ] = await Promise.all([
        client.query({
            query: GET_TICKETING_ITEM,
            variables: {id},
        }),
        client.query({
            query: GET_LIST_EVENTS_BY_ORGANIZATION,
            variables: {
                where: eventWhere,
            },
        }),
        client.query({
            query: GET_ITEM_CATEGORIES,
            variables: {
                where: organizationId
                    ? {
                        organization_id: {
                            _in: [0, organizationId],
                        },
                        is_active: {
                            _eq: true,
                        },
                        reference_type: {
                            _eq: "item_ticketing_category",
                        },
                    }
                    : {},
            },
        }),
    ]);

    return {
        item: itemData.items_ticketing_by_pk,
        events: eventData.events,
        itemCategories: itemCategoriesData.dashboard_reference,
    };
};

export const ticketingItemCloneAction = async ({
                                                  params,
                                                  request,
                                              }: ActionFunctionArgs) => {
    const user = getState().user;
    // const {id} = params;
    const body = await request.formData();
    const priceString = body.get("price") as string;
    const price = parseFloat(priceString.length ? priceString : "0");
    const taxPercentageString = body.get("tax_percentage") as string;
    const taxPercentage = (parseFloat(taxPercentageString) / 100).toFixed(8);
    // const onSaleDate = body.get("on_sale_date") as string !== "" ? body.get("on_sale_date") : null;
    // const offSaleDate = body.get("off_sale_date") as string !== "" ? body.get("off_sale_date") : null;
    const posTokens = JSON.parse(body.get("posTokens") as string);
    const includePOSTokens = body.get("include_additional_pos_tokens") as string
    const includeEgressToken = body.get("include_egress") as string
    const unlimitedIngress = body.get("unlimited_ingress") as string
    const salesLimitBalanceStr = body.get("sales_limit_balance") as string;
    const salesLimitBalance = parseInt(salesLimitBalanceStr, 10) || null;
    const orderQuantityLimitStr = body.get("order_quantity_limit") as string;
    const orderQuantityLimit = parseInt(orderQuantityLimitStr, 10) || null;
    let tokenPayload = {} as Record<
        string,
        { balance: number; redeemable_token_id: string; redeemable_token_name: string }
    >;
    const itemName = body.get("name") as string;
    const ingressTokenQuantityStr = body.get("ingressTokenQuantity") as string;
    const ingressTokenQuantity = parseInt(ingressTokenQuantityStr, 10) || 0;
    tokenPayload[itemName] = {
        balance: ingressTokenQuantity,
        redeemable_token_id: itemName,
        redeemable_token_name: itemName,
    };
    if (includeEgressToken === "true") {
        console.log("Including Egress Token")
        const egressName = itemName + " - Egress";
        tokenPayload[egressName] = {
            balance: 1000,
            redeemable_token_id: egressName,
            redeemable_token_name: egressName,
        };
    }

    if (includePOSTokens === "true") {
        posTokens.forEach((token: { name: string; quantity: number }) => {
            if (token.name.trim() !== "") {
                tokenPayload[token.name] = {
                    balance: token.quantity,
                    redeemable_token_id: token.name,
                    redeemable_token_name: token.name,
                };
            }
        });
    }

    let input = {
        name: body.get("name"),
        is_active: body.get("is_active"),
        event_id: body.get("event_id"),
        category: body.get("category"),
        description: body.get("description"),
        additional_email_description: body.get("additional_email_description"),
        price: Math.round((price) * 100),
        tax_percentage: taxPercentage ? taxPercentage : 0,
        last_updated_by: user?.id,
        token_payload: tokenPayload,
        unlimited_ingress: unlimitedIngress,
        include_egress: includeEgressToken,
        pos_token_payload: posTokens,
        include_pos_tokens: includePOSTokens,
        sales_limit_balance: salesLimitBalance,
        order_quantity_limit: orderQuantityLimit,
        // on_sale_date: onSaleDate,
        // off_sale_date: offSaleDate,
        available_public_sale: body.get("available_public_sale"),
    };

    try {
        await client.mutate({
            mutation: CREATE_TICKETING_ITEM,
            variables: {
                input,
            },
        });
        toast.success("Ticketing Item Cloned");
        return redirect(`/ticketing-items/list`);
    } catch (error) {
        toast.error("Failed to Update Ticketing Item" + error);
    }
};

export const TicketingItemClone = () => {
    const {item, events, itemCategories} = useLoaderData() as {
        item: TicketingItem;
        events: Event[];
        itemCategories: Categories[];
    };
    const [ingressTokenQuantity, setIngressTokenQuantity] = useState(item.unlimited_ingress ? 1000 : 1);
    const [includePOSTokens, setIncludePOSTokens] = useState(item.include_pos_tokens);
    const [posTokens, setPOSTokens] = useState<POSToken[]>(item.pos_token_payload);
    const [price, setPrice] = useState<number>(item.price / 100);
    const [taxPercentage, setTaxPercentage] = useState(
        Math.round(item.tax_percentage * 100 * 100) / 100
    );
    // const timezone = 'UTC';
    // const [onSaleDate, setOnSaleDate] = useState(
    //     item.on_sale_date
    //         ? toZoned(parseAbsolute(item.on_sale_date, timezone), timezone)
    //         : null
    // );
    //
    // const [offSaleDate, setOffSaleDate] = useState(
    //     item.off_sale_date
    //         ? toZoned(parseAbsolute(item.off_sale_date, timezone), timezone)
    //         : null
    // );
    const [content, setContent] = useState<string>(item.description ?? null);
    const [additionalContent, setAdditionalContent] = useState<string>(item.additional_email_description ?? null);
    const handleTaxPercentageChange = (value: string | number | undefined) => {
        if (typeof value === "string") {
            setTaxPercentage(parseFloat(value) || 0);
        }
    };
    const handlePriceChange = (value: number) => {
        setPrice(value);
    };
    const handleAddTokenSet = () => {
        setPOSTokens([...posTokens, {name: "", quantity: 0}]);
    };
    const handleRemoveTokenSet = () => {
        if (posTokens.length > 0) {
            setPOSTokens(posTokens.slice(0, -1));
        }
    };
    const handleTokenChange = (
        index: number,
        field: keyof POSToken,
        value: string | number | undefined
    ) => {
        setPOSTokens((prevTokens) => {
            return prevTokens.map((token, i) =>
                i === index ? { ...token, [field]: field === "quantity" ? Number(value) || 0 : String(value) } : token
            );
        });
    };
    // const { ItemsPermission } = usePermissions("ticketingReporting");
    // const fetcher = useFetcher();
    const [posTokensString, setPOSTokensString] = useState(JSON.stringify(posTokens));

    useEffect(() => {
        setPOSTokensString(JSON.stringify(posTokens));
    }, [posTokens]);

    const clonedTicketingItemName = `${item.name} - clone`;
    return (
        <Form
            method="post"
            action={`/ticketing-items/${item.id}/clone`}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
        >
            <FormLayout>
                <div className="col-span-5 sm:col-span-3">
                    <Input
                        label="Ticketing Item Name"
                        name="name"
                        required
                        defaultValue={clonedTicketingItemName}
                    />
                </div>
                <IndeterminateCheckbox
                    label="Is Active?"
                    name="is_active"
                    defaultChecked={item.is_active}
                />
                <div className="col-span-2">
                    <IndeterminateCheckbox
                        label="Available for Public Sale? -uncheck for Comp Items"
                        name="available_public_sale"
                        defaultChecked={item.available_public_sale}
                    />
                </div>
                <div className="col-span-6">
                    <CustomSelect
                        title="Event"
                        name="event_id"
                        required
                        options={[
                            ...(events?.map((e) => ({
                                label: e.name,
                                value: e.id,
                            })) || []),
                        ]}
                        defaultValue={item.event_id}
                    />
                </div>
                <div className="col-span-6">
                    <CustomSelect
                        title="Item Category"
                        name="category"
                        required
                        options={[
                            ...(itemCategories?.map((v) => ({
                                label: v.reference_label,
                                value: v.reference_value,
                            })) || []),
                        ]}
                        defaultValue={item.category}
                    />
                </div>
                <div className="col-span-6">
                    <TicketingItemHTML
                        content={content}
                        setContent={setContent}
                        label="Description"
                    />
                    <Input type="hidden" name="description" value={content} />
                </div>
                <div className="col-span-6">
                    <TicketingItemHTML
                        content={additionalContent}
                        setContent={setAdditionalContent}
                        label="Additional Email Confirmation Description"
                    />
                    <Input type="hidden" name="additional_email_description" value={additionalContent} />
                </div>
                <div className="col-span-5 sm:col-span-3">
                    <PriceInput
                        label="Price"
                        name="price"
                        onChange={(value) => handlePriceChange(value ? value : 0)}
                        value={price}
                        required
                    />
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <Input
                        label="Tax % ( 7.5 = 7.5% )"
                        name="tax_percentage"
                        required
                        onChange={handleTaxPercentageChange}
                        defaultValue={taxPercentage}
                    />
                </div>
                <div className="col-span-6 sm:col-span-2">
                    <Input
                        label="Remaining Ticket Quanity Limit"
                        name="sales_limit_balance"
                        type="number"
                        defaultValue={item.sales_limit_balance}
                    />
                </div>
                <div className="col-span-6 sm:col-span-1">
                    <p className="block text-lg font-medium text-gray-700">
                        Initial Ticket Limit: {item.sales_limit}
                    </p>
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <Input
                        label="Order Quantity Limit"
                        name="order_quantity_limit"
                        type="number"
                        defaultValue={item.order_quantity_limit}
                    />
                </div>
                {/*<div className="col-span-2">*/}
                {/*    <DatePicker*/}
                {/*        label="On Sale Date"*/}
                {/*        name="on_sale_date"*/}
                {/*        value={onSaleDate}*/}
                {/*        onChange={setOnSaleDate}*/}
                {/*        includeTime={true}*/}
                {/*        granularity="minute"*/}
                {/*        hourCycle={12}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="col-span-2">*/}
                {/*    <DatePicker*/}
                {/*        label="Off Sale Date"*/}
                {/*        name="off_sale_date"*/}
                {/*        value={offSaleDate}*/}
                {/*        onChange={setOffSaleDate}*/}
                {/*        includeTime={true}*/}
                {/*        granularity="minute"*/}
                {/*        hourCycle={12}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="col-span-2">
                    <IndeterminateCheckbox
                        label="Unlimited Ingress?"
                        name="unlimited_ingress"
                        defaultChecked={item.unlimited_ingress}
                        onChange={(checked) => setIngressTokenQuantity(checked ? 1000 : 1)}
                    />
                </div>
                <div className="col-span-4">
                    <IndeterminateCheckbox
                        label="Include Egress?"
                        name="include_egress"
                        defaultChecked={item.include_egress}
                    />
                </div>
                <div className="col-span-6">
                    <IndeterminateCheckbox
                        label="Include Point-Of-Sale Tokens?"
                        name="include_additional_pos_tokens"
                        onChange={(checked) => {
                            setIncludePOSTokens(checked as boolean)
                            if (!checked) {
                                setPOSTokens([{name: "", quantity: 0}])
                            }
                        }}
                        defaultChecked={item.include_pos_tokens}
                    />
                </div>
                {includePOSTokens && (
                    <div className="col-span-6">
                        {posTokens.map((token, index) => (
                            <div key={index} className="grid grid-cols-2 gap-4">
                                <Input
                                    label="Token Name"
                                    name={`pos_tokens[${index}][name]`}
                                    value={token.name}
                                    onChange={(value) => handleTokenChange(index, "name", String(value))} // Ensure it's a string
                                    required
                                />
                                <Input
                                    label="Token Quantity"
                                    name={`pos_tokens[${index}][quantity]`}
                                    value={token.quantity}
                                    type="number"
                                    onChange={(value) => handleTokenChange(index, "quantity", Number(value) || 0)} // Ensure it's a number
                                    required
                                />
                            </div>
                        ))}
                        <div className="mt-2">
                            <PrimaryButton type="button" onClick={handleAddTokenSet}>
                                + Add Token
                            </PrimaryButton>
                            {posTokens.length > 0 && (
                                <PrimaryButton type="button" onClick={handleRemoveTokenSet}>
                                    - Remove Token
                                </PrimaryButton>
                            )}
                        </div>
                    </div>
                )}
                <input type="hidden" name="posTokens" value={posTokensString} />
                <input
                    type="hidden"
                    value={ingressTokenQuantity}
                    name="ingressTokenQuantity"
                />
            </FormLayout>
        </Form>
    );
};
